import React, { useState } from 'react'
import './ExportLabelPopup.scss'
import { AnnotationFormatType } from "../../../data/enums/AnnotationFormatType";
import { RectLabelsExporter } from "../../../logic/export/RectLabelsExporter";
import { LabelType } from "../../../data/enums/LabelType";
import { ILabelFormatData } from "../../../interfaces/ILabelFormatData";
import { PointLabelsExporter } from "../../../logic/export/PointLabelsExport";
import { PolygonLabelsExporter } from "../../../logic/export/polygon/PolygonLabelsExporter";
import { PopupActions } from "../../../logic/actions/PopupActions";
import { LineLabelsExporter } from "../../../logic/export/LineLabelExport";
import { TagLabelsExporter } from "../../../logic/export/TagLabelsExport";
import GenericLabelTypePopup from "../GenericLabelTypePopup/GenericLabelTypePopup";
import { EnExportFormatData, zh_HansEnExportFormatData, zh_HantEnExportFormatData } from "../../../data/ExportFormatData";
import { AppState } from "../../../store";
import { connect } from "react-redux";

interface IProps {
    activeLabelType: LabelType,
}

// http://localhost/?groupid=31fd3824-7995-11ec-aba0-0aee66466c7b&groupnum=1
const getQueryVariable = (variable: string) => {
    // 获取url参数
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}

const type = getQueryVariable('type');

const ExportLabelPopups: React.FC<IProps> = ({ activeLabelType }) => {
    const [labelType, setLabelType] = useState(activeLabelType);
    const [exportFormatType, setExportFormatType] = useState(null);

    const onAccept = (labelType: LabelType) => {
        switch (labelType) {
            case LabelType.RECT:
                RectLabelsExporter.export(exportFormatType);
                break;
            case LabelType.POINT:
                PointLabelsExporter.export(exportFormatType);
                break;
            case LabelType.LINE:
                LineLabelsExporter.export(exportFormatType);
                break;
            case LabelType.POLYGON:
                PolygonLabelsExporter.export(exportFormatType);
                break;
            case LabelType.IMAGE_RECOGNITION:
                TagLabelsExporter.export(exportFormatType);
                break;
        }
        PopupActions.close();
    };

    const onReject = (labelType: LabelType) => {
        PopupActions.close();
    };

    var locale = localStorage.getItem("locale");

    const onSelect = (exportFormatType: AnnotationFormatType) => {
        setExportFormatType(exportFormatType);
    };

    const getOptions = (exportFormatData: ILabelFormatData[]) => {
        return exportFormatData.map((entry: ILabelFormatData) => {
            return <div
                className="OptionsItem"
                onClick={() => onSelect(entry.type)}
                key={entry.type}
            >
                {entry.type === exportFormatType ?
                    <img
                        draggable={false}
                        src={"ico/checkbox-checked.png"}
                        alt={"checked"}
                    /> :
                    <img
                        draggable={false}
                        src={"ico/checkbox-unchecked.png"}
                        alt={"unchecked"}
                    />}
                {entry.label}
            </div>
        })
    };

    const renderInternalContent = (labelType: LabelType) => {
        // setExportFormatType('VOC')
        var str = '';
        if (type) {
            if (type === "3" || type === "0" || type === "1") {
                setExportFormatType('YOLO')
                str = "YOLO"
            }
            if (type === "7" || type === "4" || type === "5") {
                setExportFormatType('VOC')
                str = "VOC XML"
            }
            return [
                <div className="Message" style={{ border: 'none' }}>
                    {locale === "en" ? "Export the " + str + ". Zip file package ." : (locale === "zh-Hans" ? "导出" + str + "文件格式.zip文件包。" : "導出" + str + "檔案格式.zip資料包。")}
                    {/* {locale == "en" ? "Select label type and the file format you would like to use to export labels." : (locale == "zh-Hans" ? "选择标签类型和要用于导出标签的文件格式。" : "選擇標籤類型和要用於導出標籤的文件格式。")} */}
                    {/* <p style={{color:'red'}}>（{locale === "en" ? "*Note: If you upload an image with a wrong format or naming, we will delete the image." : (locale === "zh-Hans" ? 
                "*注：如果你上传的某张图片格式或者命名有问题，我们将删除该图片。" : "*注：如果你上傳的某張圖片格式或者命名有問題，我們將刪除該圖片。")}）</p> */}

                </div>,
                // <div className="Options">
                //     {locale == "en" ? getOptions(EnExportFormatData[labelType]): (locale == "zh-Hans" ? getOptions(zh_HansEnExportFormatData[labelType]) : getOptions(zh_HantEnExportFormatData[labelType]))}
                // </div>
            ]
        }
        else {
            return [
                <div className="Message" style={{ border: 'none' }}>
                    {/* {locale === "en" ? "Export the " + str + ". Zip file package ." : (locale === "zh-Hans" ? "导出" + str + "文件格式.zip文件包。" : "導出" + str + "檔案格式.zip資料包。")} */}
                    {locale == "en" ? "Select label type and the file format you would like to use to export labels." : (locale == "zh-Hans" ? "选择标签类型和要用于导出标签的文件格式。" : "選擇標籤類型和要用於導出標籤的文件格式。")}
                    {/* <p style={{color:'red'}}>（{locale === "en" ? "*Note: If you upload an image with a wrong format or naming, we will delete the image." : (locale === "zh-Hans" ? 
                "*注：如果你上传的某张图片格式或者命名有问题，我们将删除该图片。" : "*注：如果你上傳的某張圖片格式或者命名有問題，我們將刪除該圖片。")}）</p> */}

                </div>,
                <div className="Options">
                    {locale == "en" ? getOptions(EnExportFormatData[labelType]): (locale == "zh-Hans" ? getOptions(zh_HansEnExportFormatData[labelType]) : getOptions(zh_HantEnExportFormatData[labelType]))}
                </div>
            ]
        }
    }

    const onLabelTypeChange = (labelType: LabelType) => {
        setLabelType(labelType);
        setExportFormatType(null);
    }

    return (
        <GenericLabelTypePopup
            activeLabelType={labelType}
            title={locale == "en" ? `Export ${labelType.toLowerCase()} annotations` : (locale == "zh-Hans" ? `导出${labelType.toLowerCase()}标记文件` : `導出${labelType.toLowerCase()}標記文件`)}
            onLabelTypeChange={onLabelTypeChange}
            acceptLabel={locale == "en" ? "Confirm" : (locale == "zh-Hans" ? "确认" : "確認")}
            onAccept={onAccept}
            disableAcceptButton={!exportFormatType}
            rejectLabel={locale == "en" ? "Cancel" : (locale == "zh-Hans" ? "取消" : "取消")}
            onReject={onReject}
            renderInternalContent={renderInternalContent}
        />
    )
};

const mapDispatchToProps = {};

const mapStateToProps = (state: AppState) => ({
    activeLabelType: state.labels.activeLabelType,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportLabelPopups);