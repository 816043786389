import React from 'react';
import './TopNavigationBar.scss';
import StateBar from "../StateBar/StateBar";
import {PopupWindowType} from "../../../data/enums/PopupWindowType";
import {AppState} from "../../../store";
import {connect} from "react-redux";
import {updateActivePopupType, updateProjectData} from "../../../store/general/actionCreators";
import TextInput from "../../Common/TextInput/TextInput";
import {ImageButton} from "../../Common/ImageButton/ImageButton";
import {Settings} from "../../../settings/Settings";
import {ProjectData} from "../../../store/general/types";
import DropDownMenu from "./DropDownMenu/DropDownMenu";

interface IProps {
    updateActivePopupType: (activePopupType: PopupWindowType) => any;
    updateProjectData: (projectData: ProjectData) => any;
    projectData: ProjectData;
}

const TopNavigationBar: React.FC<IProps> = ({updateActivePopupType, updateProjectData, projectData}) => {
    const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.setSelectionRange(0, event.target.value.length);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
            .toLowerCase()
            .replace(' ', '-');

        updateProjectData({
            ...projectData,
            name: value
        })
    };

    const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if(event.target.value == "English") {
            localStorage.setItem("locale", "en");
        } else if(event.target.value == "繁体中文") {
            localStorage.setItem("locale", "zh-Hant");
        } else {
            localStorage.setItem("locale", "zh-Hans");
        }
        window.location.reload();
    }

    var locale = localStorage.getItem("locale");
    const style: React.CSSProperties = {
        marginRight: 0,
        color: "white",
        background: "#171717",
        border: "none",
        outline: "none"
    }
    const langStyle : React.CSSProperties = {
        textAlign: 'center'
    }
    return (
        <div className="TopNavigationBar">
            <StateBar/>
            <div className="TopNavigationBarWrapper">
                <div className="NavigationBarGroupWrapper">
                    <div
                        className="Header"
                        onClick={() => updateActivePopupType(PopupWindowType.EXIT_PROJECT)}
                    >
                        {locale == "en" ? "Powered by MakeSense.ai" : (locale == "zh-Hans" ? "由MakeSense.ai提供支持" : "由MakeSense.ai提供支持")}
                    </div>
                </div>
                <div className="NavigationBarGroupWrapper">
                    <DropDownMenu/>
                </div>
                <div className="NavigationBarGroupWrapper" style={langStyle} title={locale == "en" ? "Switching display language" : (locale == "zh-Hans" ? "切换显示语言" : "切換顯示語言")}>
                    <select id="select" style={style} onChange={changeLanguage}>
			            <option value="English" selected={locale == "en" ? true : false}>English</option>
			            <option value="繁体中文" selected={locale == "zh-Hant" ? true : false}>繁体中文</option>
			            <option value="简体中文" selected={locale == "zh-Hans" ? true : false}>简体中文</option>
		            </select>
                </div>
                <div className="NavigationBarGroupWrapper middle" title={locale == "en" ? "Click modify project name" : (locale == "zh-Hans" ? "点击修改项目名" : "點擊修改項目名")}>
                    <div className="ProjectName">{locale == "en" ? "Project Name:" : (locale == "zh-Hans" ? "项目名:" : "項目名:")}</div>
                    <TextInput
                        key={"ProjectName"}
                        isPassword={false}
                        value={projectData.name}
                        onChange={onChange}
                        onFocus={onFocus}
                    />
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    updateActivePopupType,
    updateProjectData
};

const mapStateToProps = (state: AppState) => ({
    projectData: state.general.projectData
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TopNavigationBar);