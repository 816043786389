import React, { useState } from "react";
import classNames from 'classnames'
import './DropDownMenu.scss';
import { EnDropDownMenuData, zh_HansDropDownMenuData, zh_HantDropDownMenuData, DropDownMenuNode } from "../../../../data/info/DropDownMenuData";
import { EventType } from "../../../../data/enums/EventType";
import { updatePreventCustomCursorStatus } from "../../../../store/general/actionCreators";
import { AppState } from "../../../../store";
import { connect } from "react-redux";
import { zh_HansLabelToolkitData } from "../../../../data/info/LabelToolkitData";
import { any } from "@tensorflow/tfjs";
import { ProjectType } from "../../../../data/enums/ProjectType";

interface IProps {
    projectType: ProjectType;
    updatePreventCustomCursorStatus: (preventCustomCursor: boolean) => any;
}

const getQueryVariable = (variable: string) => {
    // 获取url参数
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}
const type = getQueryVariable('type');

const DropDownMenu: React.FC<IProps> = ({ projectType, updatePreventCustomCursorStatus }) => {
    const topAnchor = 35;

    const [activeTabIdx, setActiveTabIdx] = useState(null);
    const [activeDropDownAnchor, setDropDownAnchor] = useState(null);

    const onTabClick = (tabIdx: number, event) => {
        if (activeTabIdx === null) {
            document.addEventListener(EventType.MOUSE_DOWN, onMouseDownBeyondDropDown);
        }

        if (activeTabIdx === tabIdx) {
            setActiveTabIdx(null);
            setDropDownAnchor(null);
        } else {
            setActiveTabIdx(tabIdx);
            setDropDownAnchor({ x: event.target.offsetLeft, y: topAnchor });
        }
    }

    const onMouseEnterWindow = (event) => {
        updatePreventCustomCursorStatus(true);
    }

    const onMouseLeaveWindow = (event) => {
        updatePreventCustomCursorStatus(false);
    }

    const onMouseDownBeyondDropDown = (event) => {
        if (event.target.classList.contains("DropDownMenuTab") || event.target.classList.contains("DropDownMenuContentOption")) {
            return;
        }
        setActiveTabIdx(null);
        document.removeEventListener(EventType.MOUSE_DOWN, onMouseDownBeyondDropDown);
    }

    const onMouseEnterTab = (tabIdx: number, event) => {
        if (activeTabIdx !== null && activeTabIdx !== tabIdx) {
            setActiveTabIdx(tabIdx);
            setDropDownAnchor({ x: event.target.offsetLeft, y: topAnchor });
        }
    }

    const getDropDownMenuTabClassName = (tabIdx: number) => {
        return classNames(
            "DropDownMenuTab",
            { "active": tabIdx === activeTabIdx }
        );
    };

    const getDropDownMenuContentOption = (disabled: boolean) => {
        return classNames(
            "DropDownMenuContentOption",
            { "active": !disabled }
        );
    }

    const getDropDownContent = () => {
        if (localStorage.getItem("locale") == "en") {
            return EnDropDownMenuData.map((data: DropDownMenuNode, index: number) => getDropDownTab(data, index))
        }
        else if (localStorage.getItem("locale") == "zh-Hans") {
            return zh_HansDropDownMenuData.map((data: DropDownMenuNode, index: number) => getDropDownTab(data, index))
        }
        else {
            return zh_HantDropDownMenuData.map((data: DropDownMenuNode, index: number) => getDropDownTab(data, index))
        }
    }

    const wrapOnClick = (onClick?: () => void, disabled?: boolean): () => void => {
        return () => {
            if (!!disabled) return;
            if (!!onClick) onClick();
            setActiveTabIdx(null);
            updatePreventCustomCursorStatus(false);
            document.removeEventListener(EventType.MOUSE_DOWN, onMouseDownBeyondDropDown);
        }
    }

    const getDropDownTab = (data: DropDownMenuNode, index: number) => {
        return <div
            className={getDropDownMenuTabClassName(index)}
            key={index}
            onClick={(event) => onTabClick(index, event)}
            title={data.title}
            onMouseEnter={(event) => onMouseEnterTab(index, event)}
        >
            <img
                draggable={false}
                src={data.imageSrc}
                alt={data.imageAlt}
            />
            {data.name}
        </div>
    }

    const getDropDownWindow = (data: DropDownMenuNode) => {
        // console.log(type)
        // console.log(projectType)
        if (activeTabIdx !== null) {
            const style: React.CSSProperties = {
                top: 35,
                left: activeDropDownAnchor.x,
                height: 40 * ((!type || type === '1') ? data.children.length - 1 : data.children.length) + 10
            }
            const otherStyle: React.CSSProperties = {
                top: 35,
                left: activeDropDownAnchor.x,
                height: 40 * ((!type) ? data.children.length - 2 : data.children.length - 1) + 10
            }
            return <div
                className={"DropDownMenuContent"}
                style={ projectType != "IMAGE_RECOGNITION" ? style : otherStyle }
                onMouseEnter={onMouseEnterWindow}
                onMouseLeave={onMouseLeaveWindow}
            >
                {data.children.map((element: DropDownMenuNode, index: number) => {
                    if (type) {
                        if (type === "1") {
                            return index !== 4 && <div className={getDropDownMenuContentOption(element.disabled)}
                                onClick={wrapOnClick(element.onClick, element.disabled)}
                                key={index}
                                title={element.title}
                            >
                                <div className="Marker" />
                                <img src={element.imageSrc} alt={element.imageAlt} />
                                {element.name}
                            </div>
                        } else {
                            return <div className={getDropDownMenuContentOption(element.disabled)}
                                onClick={wrapOnClick(element.onClick, element.disabled)}
                                key={index}
                                title={element.title}
                            >
                                <div className="Marker" />
                                <img src={element.imageSrc} alt={element.imageAlt} />
                                {element.name}
                            </div>
                        }
                    } else {
                        if (projectType == "IMAGE_RECOGNITION") {
                            return (index !== 3 && index !== 2) && <div className={getDropDownMenuContentOption(element.disabled)}
                                onClick={wrapOnClick(element.onClick, element.disabled)}
                                key={index}
                                title={element.title}
                            >
                                <div className="Marker" />
                                <img src={element.imageSrc} alt={element.imageAlt} />
                                {element.name}
                            </div>
                        }
                        else {
                            return index !== 3 && <div className={getDropDownMenuContentOption(element.disabled)}
                                onClick={wrapOnClick(element.onClick, element.disabled)}
                                key={index}
                                title={element.title}
                            >
                                <div className="Marker" />
                                <img src={element.imageSrc} alt={element.imageAlt} />
                                {element.name}
                            </div>
                        }
                    }
                })}
            </div>
        } else {
            return null;
        }
    }

    return (<div className="DropDownMenuWrapper">
        <>
            {getDropDownContent()}
            {localStorage.getItem("locale") == "en" ? getDropDownWindow(EnDropDownMenuData[activeTabIdx]) : (localStorage.getItem("locale") == "zh-Hans" ? getDropDownWindow(zh_HansDropDownMenuData[activeTabIdx]) : getDropDownWindow(zh_HantDropDownMenuData[activeTabIdx]))}
        </>
    </div>)
}

const mapDispatchToProps = {
    updatePreventCustomCursorStatus,
};

const mapStateToProps = (state: AppState) => ({
    projectType: state.general.projectData.type
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DropDownMenu);