import {HotKeyAction} from "../../data/HotKeyAction";
import {EditorModel} from "../../staticModels/EditorModel";
import {LabelType} from "../../data/enums/LabelType";
import {EditorData} from "../../data/EditorData";
import {EditorActions} from "../actions/EditorActions";
import {PolygonRenderEngine} from "../render/PolygonRenderEngine";
import {BaseContext} from "./BaseContext";
import {ImageActions} from "../actions/ImageActions";
import {ViewPortActions} from "../actions/ViewPortActions";
import {Direction} from "../../data/enums/Direction";
import {PlatformUtil} from "../../utils/PlatformUtil";
import {LabelActions} from "../actions/LabelActions";
import {LineRenderEngine} from "../render/LineRenderEngine";
import {Settings} from "../../settings/Settings";
import {PopupWindowType} from "../../data/enums/PopupWindowType";
import {ContextManager} from "../context/ContextManager";
import {store} from "../../index";
import {updateActivePopupType} from "../../store/general/actionCreators";

export class EditorContext extends BaseContext {
    public static actions: HotKeyAction[] = [
        {
            keyCombo: ["Enter"],
            action: (event: KeyboardEvent) => {
                if (EditorModel.supportRenderingEngine && EditorModel.supportRenderingEngine.labelType === LabelType.POLYGON) {
                    const editorData: EditorData = EditorActions.getEditorData();
                    (EditorModel.supportRenderingEngine as PolygonRenderEngine).addLabelAndFinishCreation(editorData);
                }
                EditorActions.fullRender();
            }
        },
        {
            keyCombo: ["Escape"],
            action: (event: KeyboardEvent) => {
                // if (EditorModel.supportRenderingEngine) {
                //     switch (EditorModel.supportRenderingEngine.labelType) {
                //         case LabelType.POLYGON:
                //             (EditorModel.supportRenderingEngine as PolygonRenderEngine).cancelLabelCreation();
                //             break;
                //         case LabelType.LINE:
                //             (EditorModel.supportRenderingEngine as LineRenderEngine).cancelLabelCreation();
                //             break;
                //     }
                // }
                // EditorActions.fullRender();
                const popupType: PopupWindowType = PopupWindowType.EXIT_PROJECT;
                const canBeClosed: boolean = Settings.CLOSEABLE_POPUPS.includes(popupType);
                if (canBeClosed) {
                    store.dispatch(updateActivePopupType(PopupWindowType.EXIT_PROJECT));
                    ContextManager.restoreCtx();
                }
            }
        },
        {
            // keyCombo: PlatformUtil.isMac(window.navigator.userAgent) ? ["Alt", "ArrowLeft"] : ["Control", "ArrowLeft"],
            keyCombo: [","],
            action: (event: KeyboardEvent) => {
                ImageActions.getPreviousImage()
            }
        },
        {
            keyCombo: ["."],
            action: (event: KeyboardEvent) => {
                ImageActions.getNextImage();
            }
        },
        {
            keyCombo: ["+"],
            action: (event: KeyboardEvent) => {
                ViewPortActions.zoomIn();
            }
        },
        {
            keyCombo: ["-"],
            action: (event: KeyboardEvent) => {
                ViewPortActions.zoomOut();
            }
        },
        {
            keyCombo: ["ArrowRight"],
            action: (event: KeyboardEvent) => {
                event.preventDefault();
                ViewPortActions.translateViewPortPosition(Direction.RIGHT);
            }
        },
        {
            keyCombo: ["ArrowLeft"],
            action: (event: KeyboardEvent) => {
                event.preventDefault();
                ViewPortActions.translateViewPortPosition(Direction.LEFT);
            }
        },
        {
            keyCombo: ["ArrowUp"],
            action: (event: KeyboardEvent) => {
                event.preventDefault();
                ViewPortActions.translateViewPortPosition(Direction.BOTTOM);
            }
        },
        {
            keyCombo: ["ArrowDown"],
            action: (event: KeyboardEvent) => {
                event.preventDefault();
                ViewPortActions.translateViewPortPosition(Direction.TOP);
            }
        },
        {
            keyCombo: ["Delete"],
            action: (event: KeyboardEvent) => {
                LabelActions.deleteActiveLabel();
            }
        },
        {
            keyCombo: ["0"],
            action: (event: KeyboardEvent) => {
                ImageActions.setActiveLabelOnActiveImage(0);
                EditorActions.fullRender();
            }
        },
        {
            keyCombo: ["1"],
            action: (event: KeyboardEvent) => {
                ImageActions.setActiveLabelOnActiveImage(1);
                EditorActions.fullRender();
            }
        },
        {
            keyCombo: ["2"],
            action: (event: KeyboardEvent) => {
                ImageActions.setActiveLabelOnActiveImage(2);
                EditorActions.fullRender();
            }
        },
        {
            keyCombo: ["3"],
            action: (event: KeyboardEvent) => {
                ImageActions.setActiveLabelOnActiveImage(3);
                EditorActions.fullRender();
            }
        },
        {
            keyCombo: ["4"],
            action: (event: KeyboardEvent) => {
                ImageActions.setActiveLabelOnActiveImage(4);
                EditorActions.fullRender();
            }
        },
        {
            keyCombo: ["5"],
            action: (event: KeyboardEvent) => {
                ImageActions.setActiveLabelOnActiveImage(5);
                EditorActions.fullRender();
            }
        },
        {
            keyCombo: ["6"],
            action: (event: KeyboardEvent) => {
                ImageActions.setActiveLabelOnActiveImage(6);
                EditorActions.fullRender();
            }
        },
        {
            keyCombo: ["7"],
            action: (event: KeyboardEvent) => {
                ImageActions.setActiveLabelOnActiveImage(7);
                EditorActions.fullRender();
            }
        },
        {
            keyCombo: ["8"],
            action: (event: KeyboardEvent) => {
                ImageActions.setActiveLabelOnActiveImage(8);
                EditorActions.fullRender();
            }
        },
        {
            keyCombo: ["9"],
            action: (event: KeyboardEvent) => {
                ImageActions.setActiveLabelOnActiveImage(9);
                EditorActions.fullRender();
            }
        }
    ];
}