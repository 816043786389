import React, { useState } from 'react'
import './LoadLabelNamesPopup.scss'
import { AppState } from "../../../store";
import { connect } from "react-redux";
import { updateLabelNames } from "../../../store/labels/actionCreators";
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup";
import { PopupWindowType } from "../../../data/enums/PopupWindowType";
import { updateActivePopupType } from "../../../store/general/actionCreators";
import { useDropzone } from "react-dropzone";
import { AcceptedFileType } from "../../../data/enums/AcceptedFileType";
import { LabelName } from "../../../store/labels/types";
import { YOLOUtils } from "../../../logic/import/yolo/YOLOUtils";

interface IProps {
    updateActivePopupType: (activePopupType: PopupWindowType) => any;
    updateLabels: (labels: LabelName[]) => any;
}

const LoadLabelNamesPopup: React.FC<IProps> = ({ updateActivePopupType, updateLabels }) => {
    const [labelsList, setLabelsList] = useState([]);
    const [invalidFileLoadedStatus, setInvalidFileLoadedStatus] = useState(false);

    var locale = localStorage.getItem("locale");

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: AcceptedFileType.TEXT,
        multiple: false,
        onDrop: (acceptedFiles) => {
            if (acceptedFiles.length === 1) {
                YOLOUtils.loadLabelsList(acceptedFiles[0], onSuccess, onFailure);
            }
        }
    });

    const onSuccess = (labelsList: LabelName[]) => {
        setLabelsList(labelsList);
        setInvalidFileLoadedStatus(false);
    };

    const onFailure = () => {
        setInvalidFileLoadedStatus(true);
    };

    const onAccept = () => {
        
    };

    const onReject = () => {
        if (labelsList.length > 0) {
            updateLabels(labelsList);
        }
        updateActivePopupType(PopupWindowType.INSERT_LABEL_NAMES);
    };

    const getDropZoneContent = () => {
        if (invalidFileLoadedStatus)
            return <>
                <input {...getInputProps()} />
                <img
                    draggable={false}
                    alt={"upload"}
                    src={"ico/box-opened.png"}
                />
                <p className="extraBold">{locale == "en" ? "Loading of labels file was unsuccessful" : (locale == "zh-Hans" ? "标签文件加载失败" : "標籤文件加載失敗")}</p>
                <p className="extraBold">{locale == "en" ? "Try again" : (locale == "zh-Hans" ? "再试一次" : "再試一次")}</p>
            </>;
        else if (acceptedFiles.length === 0)
            return <>
                <input {...getInputProps()} />
                <img
                    draggable={false}
                    alt={"upload"}
                    src={"ico/box-opened.png"}
                />
                <p className="extraBold">{locale == "en" ? "Drop labels file" : (locale == "zh-Hans" ? "拖拽标签文件" : "拖拽標籤文件")}</p>
                <p>or</p>
                <p className="extraBold">{locale == "en" ? "Click here to select it" : (locale == "zh-Hans" ? "单击此处选择文件" : "單擊此處選擇文件")}</p>
            </>;
        else if (labelsList.length === 1)
            return <>
                <img
                    draggable={false}
                    alt={"uploaded"}
                    src={"ico/box-closed.png"}
                />
                <p className="extraBold">{locale == "en" ? "only 1 label found" : (locale == "zh-Hans" ? "仅找到1个标签" : "僅找到1個標籤")}</p>
            </>;
        else
            return <>
                <img
                    draggable={false}
                    alt={"uploaded"}
                    src={"ico/box-closed.png"}
                />
                <p className="extraBold">{locale == "en" ? labelsList.length + " labels found" : (locale == "zh-Hans" ? "找到"+ labelsList.length +"个标签" : "找到"+ labelsList.length +"個標籤")}</p>
            </>;
    };

    const renderContent = () => {
        return (<div className="LoadLabelsPopupContent">
            <div className="Message">
                {locale == "en" ? "Load a text file with a list of labels you are planning to use. The names of" +
                "each label should be separated by new line. If you don't have a prepared file, no problem. You can" +
                "create your own list now." : (locale == "zh-Hans" ? "加载一个文本文件，其中包含您计划使用的标签列表。" +
                "每个标签的名称应由换行符分隔。如果您没有准备好的文件，那就没问题了。您现在可以创建自己的列表。" : "加載一個" +
                "文本文件，其中包含您計劃使用的標籤列表。每個標籤的名稱應由換行符分隔。如果您沒有準備好的文件，那就沒問題了。您" +
                "現在可以創建自己的列表。")}
            </div>
            <div {...getRootProps({ className: 'DropZone' })}>
                {getDropZoneContent()}
            </div>
        </div>);
    };

    return (
        <GenericYesNoPopup
            title={locale == "en" ? "Load file with labels description" : (locale == "zh-Hans" ? "加载带有标签说明的文件" : "加載帶有標籤說明的文件")}
            renderContent={renderContent}
            acceptLabel={locale == "en" ? "Start project":(locale == "zh-Hans" ? "开始项目" : "開始項目")}
            onAccept={onAccept}
            skipAcceptButton={true}
            disableAcceptButton={labelsList.length === 0}
            rejectLabel={locale == "en" ? "Create labels list" : (locale == "zh-Hans" ? "创建标签列表" : "創建標籤列表")}
            onReject={onReject}
        />
    );
};

const mapDispatchToProps = {
    updateActivePopupType,
    updateLabels: updateLabelNames
};

const mapStateToProps = (state: AppState) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoadLabelNamesPopup);