import React, { useState } from 'react';
import './EditorView.scss';
import EditorContainer from "./EditorContainer/EditorContainer";
import {PopupWindowType} from "../../data/enums/PopupWindowType";
import {AppState} from "../../store";
import {connect} from "react-redux";
import classNames from "classnames";
import TopNavigationBar from "./TopNavigationBar/TopNavigationBar";

interface IProps {
    activePopupType: PopupWindowType;
}

const locale = localStorage.getItem("locale");

const EditorView: React.FC<IProps> = ({activePopupType}) => {
    const [isHide,setIsHide] = useState(false)

    const getClassName = () => {
        return classNames(
            "EditorView",
            {
                "withPopup": !!activePopupType
            }
        );
    };
    // let isHide = false

    return (
        <div
            className={getClassName()}
            draggable={false}
        >
            <TopNavigationBar/>
            <EditorContainer/>
            <div className={isHide?'trainState':'trainStateActive trainState'}>
                <h4>{locale === "en" ? "How to Train" : (locale === "zh-Hans" ? "如何进行训练" : "如何進行訓練")}</h4>
                <p>{locale === "en" ? "Step 1: Import images" : (locale === "zh-Hans" ? "第一步：导入图片" : "第一步：導入圖片")}</p>
                <p>{locale === "en" ? "Step 2: Edit the label" : (locale === "zh-Hans" ? "第二步：编辑标签" : "第二步：編輯標籤")}</p>
                <p>{locale === "en" ? "Step 3: find the mark position and draw a line to mark" : (locale === "zh-Hans" ? "第三步：找出标记位置，并画线标记" : "第三步：找出標記位置，並畫線標記")}</p>
                <p>{locale === "en" ? "Step 4: Select the label" : (locale === "zh-Hans" ? "第四步：选择标签" : "第四步：選擇標籤")}</p>
                <p>{locale === "en" ? "Step 5: Export labels" : (locale === "zh-Hans" ? "第五步：导出标签" : "第五步：導出標籤")}</p>
                {isHide?<span onClick={()=>setIsHide(false)} className='stateRight'>&lt;</span>:
                <span onClick={()=>setIsHide(true)} className='stateLeft'>&gt;</span>}
            </div>
        </div>
    );
};

const mapStateToProps = (state: AppState) => ({
    activePopupType: state.general.activePopupType
});

export default connect(
    mapStateToProps
)(EditorView);