/* eslint-disable no-restricted-globals */
import React, { useState } from "react";
import './ImagesDropZone.scss';
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { TextButton } from "../../Common/TextButton/TextButton";
import { ImageData } from "../../../store/labels/types";
import { connect } from "react-redux";
import { addImageData, updateActiveImageIndex, addImageString } from "../../../store/labels/actionCreators";
import { AppState } from "../../../store";
import { ProjectType } from "../../../data/enums/ProjectType";
import { PopupWindowType } from "../../../data/enums/PopupWindowType";
import { updateActivePopupType, updateProjectData } from "../../../store/general/actionCreators";
import { AcceptedFileType } from "../../../data/enums/AcceptedFileType";
import { ProjectData } from "../../../store/general/types";
import { ImageDataUtil } from "../../../utils/ImageDataUtil";
import axios from "axios";

interface IProps {
    updateActiveImageIndex: (activeImageIndex: number) => any;
    addImageData: (imageData: ImageData[]) => any;
    updateProjectData: (projectData: ProjectData) => any;
    updateActivePopupType: (activePopupType: PopupWindowType) => any;
    projectData: ProjectData;
}

// http://localhost/?groupid=31fd3824-7995-11ec-aba0-0aee66466c7b&groupnum=1
const getQueryVariable = (variable: string) => {
    // 获取url参数
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}


const groupid = getQueryVariable('groupid');
const groupnum = getQueryVariable('groupnum');
const type = getQueryVariable('type');
const name = getQueryVariable('name');
var imgList = []
let interval = null
let isBiaoji = false

const ImagesDropZone: React.FC<IProps> = ({ updateActiveImageIndex, addImageData, updateProjectData, updateActivePopupType, projectData }) => {
    const [number, setNumber] = useState(0)
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        accept: AcceptedFileType.IMAGE
    } as DropzoneOptions);
    const startEditor = (projectType: ProjectType) => {
        if (acceptedFiles.length > 0 && number === 0) {
            localStorage.setItem("isSelectImage", "0");
            var list = [];
            for (var i = 0; i < acceptedFiles.length; i++) {
                list.push(0);
            }
            localStorage.setItem("isSelectLabel", JSON.stringify(list));
            updateProjectData({
                ...projectData,
                type: projectType
            });
            updateActiveImageIndex(0);
            addImageData(acceptedFiles.map((fileData: File) => ImageDataUtil.createImageDataFromFileData(fileData, null)));
            updateActivePopupType(PopupWindowType.INSERT_LABEL_NAMES);
        } else if ((number !== 0 && type === "1") || (number !== 0 && type === "5")) {
            if (!localStorage.getItem("isSelectLabel")) {
                localStorage.setItem("isSelectImage", "0");
                var list = [];
                for (var i = 0; i < imgList.length; i++) {
                    list.push(0);
                }
                localStorage.setItem("isSelectLabel", JSON.stringify(list));
            }
            addImageData(imgList.map((url: string) => ImageDataUtil.createImageDataFromFileData(null, `//traininguser-api.cocorobo.cn/names/${name}/${groupnum}/${url}`)))
            updateActiveImageIndex(0);
            updateProjectData({
                ...projectData,
                type: projectType
            });
            updateActivePopupType(PopupWindowType.INSERT_LABEL_NAMES);
        } else if (number !== 0 && name && type === "7" && !isBiaoji) {
            isBiaoji = true
            if (!localStorage.getItem("isSelectLabel")) {
                localStorage.setItem("isSelectImage", "0");
                var list = [];
                for (var i = 0; i < imgList.length; i++) {
                    list.push(0);
                }
                localStorage.setItem("isSelectLabel", JSON.stringify(list));
            }
            addImageData(imgList.map((url: string) => ImageDataUtil.createImageDataFromFileData(null, `//traininguser-api.cocorobo.cn/v831_images/${name}/${url}`)))
            updateActiveImageIndex(0);
            updateProjectData({
                ...projectData,
                type: projectType
            });
            updateActivePopupType(PopupWindowType.INSERT_LABEL_NAMES);
        }
    };

    var locale = localStorage.getItem("locale");
    var a = true;
    var b = true;
    if (acceptedFiles.length > 0) {
        const divs = document.createElement('div')
        divs.className = 'upload_img';
        divs.style.display = "none";
        for (let i = 0; i < acceptedFiles.length; i++) {
            // console.log(acceptedFiles[i], acceptedFiles[i].type !== 'image/jpeg')
            // if (!/\.(jpg)$/.test(acceptedFiles[i].name)) {
            let reads = new FileReader()
            let f = acceptedFiles[i]
            reads.readAsDataURL(f)
            reads.onload = function (e) {
                const imgs = document.createElement('img')
                imgs.src = `${this.result}`;
                imgs.id = "img" + i;
                imgs.style.width = '100%'
                imgs.style.height = '100%'
                imgs.style.display = 'none'
                setTimeout(function () {
                    divs.appendChild(imgs);
                    let canvas = document.createElement('canvas');
                    canvas.width = Number(imgs.width)
                    canvas.height = Number(imgs.height)
                    let context = canvas.getContext('2d');
                    context.drawImage(imgs, 0, 0);
                    let type = "jpg";
                    var imgData = canvas.toDataURL('image/jpeg', 1);
                    var filename = f.name.substr(0, f.name.lastIndexOf('.')) + '.' + type;
                    // console.log(imgData)
                    acceptedFiles[i] = dataURLtoFile(imgData, filename)
                    // console.log(acceptedFiles[i])
                }, 10)
            }
            // }
        }
    } else if ((groupid && groupnum && name && number === 0 && type === "1") || (groupid && groupnum && name && number === 0 && type === "5")) {
        // beta.training-api.cocorobo.cn 192.168.0.119:8011
        // https://traininguser-api.cocorobo.cn
        // imgList 3de2e11a-7b2a-11ec-a735-2a16101a060a
        axios.get(`//traininguser-api.cocorobo.cn/labelingimage?groupid=${name}&groupnum=${groupnum}`).then(res => {
            // console.log(JSON.parse(res.data.FunctionResponse))
            if (JSON.parse(res.data.FunctionResponse).length > 0) {
                imgList = JSON.parse(res.data.FunctionResponse)
                setNumber(imgList.length)
                var aaaa = imgList.length;
                localStorage.setItem("number", String(aaaa))
            } else {
                // setNumber(0)
                // alert(locale === "en" ? "The group data has been flagged for uploading or the group does not exist" : (locale === "zh-Hans" ? "'该小组数据已标记上传或小组不存在'" : "該小組數據已標記上傳或小組不存在"))
                a = false
                // console.log('11111111111')
            }


        }).catch(err => {
            alert(locale === "en" ? "Data request failed. Please contact cocorobo staff" : (locale === "zh-Hans" ? "数据请求失败，请联系可可乐博工作人员" : "數據請求失敗，請聯繫可可樂博工作人員"))
        })
    }
    else if (name && type === "7") {
        interval = setInterval(() => {
            if (!isBiaoji) {
                axios.get(`//traininguser-api.cocorobo.cn/labeling_v831_image?name=${name}`).then(res => {
                    // console.log(JSON.parse(res.data.FunctionResponse))
                    if (JSON.parse(res.data.FunctionResponse).length > 0) {
                        imgList = JSON.parse(res.data.FunctionResponse)
                        setNumber(imgList.length)
                        var aaaa = imgList.length;
                        localStorage.setItem("number", String(aaaa))
                        getDropZoneContent()
                    } else {
                        // setNumber(0)
                        // alert(locale === "en" ? "The group data has been flagged for uploading or the group does not exist" : (locale === "zh-Hans" ? "'该小组数据已标记上传或小组不存在'" : "該小組數據已標記上傳或小組不存在"))
                        a = false
                        // console.log('11111111111')
                    }


                }).catch(err => {
                    alert(locale === "en" ? "Data request failed. Please contact cocorobo staff" : (locale === "zh-Hans" ? "数据请求失败，请联系可可乐博工作人员" : "數據請求失敗，請聯繫可可樂博工作人員"))
                })
            }
            else {
                clearInterval(interval)
            }
        }, 1000)
    }
    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    const getDropZoneContent = () => {
        if (acceptedFiles.length === 0 && !groupid && type != "7") {
            return <>
                <input {...getInputProps()} />
                <img
                    draggable={false}
                    alt={"upload"}
                    src={"ico/box-opened.png"}
                />
                <p className="extraBold">{locale === "en" ? "Drop images" : (locale === "zh-Hans" ? "拖拽图片" : "拖拽圖片")}</p>
                <p>{locale === "en" ? "or" : (locale === "zh-Hans" ? "或" : "或")}</p>
                <p className="extraBold">{locale === "en" ? "Click here to select them" : (locale === "zh-Hans" ? "单击此处选择图片" : "單擊此處選擇圖片")}</p>
            </>;
        }
        else if ((acceptedFiles.length === 1 && !groupid && type != "7")) {
            if (acceptedFiles[0].size >= 400 * 1024) {
                b = false;
                return <>
                    <input {...getInputProps()} />
                    <img
                        draggable={false}
                        alt={"upload"}
                        src={"ico/box-opened.png"}
                    />
                    <p className="extraBold">{locale === "en" ? "Drop images" : (locale === "zh-Hans" ? "拖拽图片" : "拖拽圖片")}</p>
                    <p>{locale === "en" ? "or" : (locale === "zh-Hans" ? "或" : "或")}</p>
                    <p className="extraBold">{locale === "en" ? "Click here to select them" : (locale === "zh-Hans" ? "单击此处选择图片" : "單擊此處選擇圖片")}</p>
                </>;
            }
            else {
                localStorage.removeItem("isSelectLabel");
                if (!localStorage.getItem("isSelectLabel")) {
                    localStorage.setItem("isSelectImage", "0");
                    const list = [];
                    list.push(0);
                    localStorage.setItem("isSelectLabel", JSON.stringify(list));
                }
                return <>
                    <img
                        draggable={false}
                        alt={"uploaded"}
                        src={"ico/box-closed.png"}
                    />
                    <p className="extraBold">{locale == "en" ? "1 image loaded" : (locale == "zh-Hans" ? "已加载1张图片" : "已加載1張圖片")}</p>
                </>;
            }
        }
        else if ((acceptedFiles.length === 0 && groupid && groupnum && name && type === "0") || (acceptedFiles.length === 0 && groupid && groupnum && name && type === "4")) {
            return <>
                <input {...getInputProps()} />
                <img
                    draggable={false}
                    alt={"upload"}
                    src={"ico/box-opened.png"}
                />
                <p className="extraBold">{locale == "en" ? "Drop images" : (locale == "zh-Hans" ? "拖拽图片" : "拖拽圖片")}</p>
                <p>{locale == "en" ? "or" : (locale == "zh-Hans" ? "或" : "或")}</p>
                <p className="extraBold">{locale == "en" ? "Click here to select them" : (locale == "zh-Hans" ? "单击此处选择图片" : "單擊此處選擇圖片")}</p>
            </>;
        }
        else if ((acceptedFiles.length > 0 && groupid && groupnum && name && type === "0") || (acceptedFiles.length > 0 && groupid && groupnum && name && type === "4")) {
            return <>
                <input {...getInputProps()} />
                <img
                    draggable={false}
                    key={1}
                    alt={"uploaded"}
                    src={"ico/box-closed.png"}
                />
                <p className="extraBold">{locale == "en" ? acceptedFiles.length + " images loaded" : (locale == "zh-Hans" ? "已加载" + acceptedFiles.length + "张图片" : "已加載" + acceptedFiles.length + "張圖片")}</p>
            </>;
        }
        else if ((groupid && groupnum && name && type === "1") || (groupid && groupnum && name && type === "5")) {
            // if (a && b) {
            localStorage.removeItem("isSelectLabel");
            return <>
                {number === 0 ? <div>
                    <p>{locale === "en" ? "The group data has been flagged for uploading or the group does not exist" : (locale === "zh-Hans" ? "该小组数据已标记上传或小组不存在" : "該小組數據已標記上傳或小組不存在")}</p>
                </div> : <p>{locale === "en" ? number + " images loaded" : (locale === "zh-Hans" ? "已加载" + number + "张图片" : "已加載" + number + "張圖片")}</p>}
            </>;
            // }
        }
        else if (acceptedFiles.length === 0 && type === "7" && !name) {
            return <>
                <input {...getInputProps()} />
                <img
                    draggable={false}
                    alt={"upload"}
                    src={"ico/box-opened.png"}
                />
                <p className="extraBold">{locale == "en" ? "Drop images" : (locale == "zh-Hans" ? "拖拽图片" : "拖拽圖片")}</p>
                <p>{locale == "en" ? "or" : (locale == "zh-Hans" ? "或" : "或")}</p>
                <p className="extraBold">{locale == "en" ? "Click here to select them" : (locale == "zh-Hans" ? "单击此处选择图片" : "單擊此處選擇圖片")}</p>
            </>;
        }
        else if ((acceptedFiles.length === 1 && type === "7" && !name)) {
            if (acceptedFiles[0].size >= 400 * 1024) {
                b = false;
                return <>
                    <input {...getInputProps()} />
                    <img
                        draggable={false}
                        alt={"upload"}
                        src={"ico/box-opened.png"}
                    />
                    <p className="extraBold">{locale === "en" ? "Drop images" : (locale === "zh-Hans" ? "拖拽图片" : "拖拽圖片")}</p>
                    <p>{locale === "en" ? "or" : (locale === "zh-Hans" ? "或" : "或")}</p>
                    <p className="extraBold">{locale === "en" ? "Click here to select them" : (locale === "zh-Hans" ? "单击此处选择图片" : "單擊此處選擇圖片")}</p>
                </>;
            }
            else {
                localStorage.removeItem("isSelectLabel");
                if (!localStorage.getItem("isSelectLabel")) {
                    localStorage.setItem("isSelectImage", "0");
                    const list = [];
                    list.push(0);
                    localStorage.setItem("isSelectLabel", JSON.stringify(list));
                }
                return <>
                    <img
                        draggable={false}
                        alt={"uploaded"}
                        src={"ico/box-closed.png"}
                    />
                    <p className="extraBold">{locale == "en" ? "1 image loaded" : (locale == "zh-Hans" ? "已加载1张图片" : "已加載1張圖片")}</p>
                </>;
            }
        }
        else if (number === 0 && name && type === "7") {
            return <>
                <input {...getInputProps()} />
                <img
                    draggable={false}
                    alt={"upload"}
                    src={"ico/box-opened.png"}
                />
                <p className="extraBold">{locale == "en" ? "Drop images" : (locale == "zh-Hans" ? "拖拽图片" : "拖拽圖片")}</p>
                <p>{locale == "en" ? "or" : (locale == "zh-Hans" ? "或" : "或")}</p>
                <p className="extraBold">{locale == "en" ? "Click here to select them" : (locale == "zh-Hans" ? "单击此处选择图片" : "單擊此處選擇圖片")}</p>
            </>;
        }
        else if (number > 0 && name && type === "7") {
            // localStorage.removeItem("isSelectLabel");
            // return <>
            //     {/* {number === 0 ? <div style={{ textAlign: "center" }}>
            //         <input {...getInputProps()} />
            //         <img
            //             draggable={false}
            //             alt={"upload"}
            //             src={"ico/box-opened.png"}
            //         />
            //         <p className="extraBold">{locale == "en" ? "Drop images" : (locale == "zh-Hans" ? "拖拽图片" : "拖拽圖片")}</p>
            //         <p>{locale == "en" ? "or" : (locale == "zh-Hans" ? "或" : "或")}</p>
            //         <p className="extraBold">{locale == "en" ? "Click here to select them" : (locale == "zh-Hans" ? "单击此处选择图片" : "單擊此處選擇圖片")}</p>
            //     </div> : <p>{locale === "en" ? number + " images loaded" : (locale === "zh-Hans" ? "已加载" + number + "张图片" : "已加載" + number + "張圖片")}</p>} */}
            // </>;
            return imgList.map((image: String) => {
                return <img src={"//traininguser-api.cocorobo.cn/v831_images/" + name + "/" + image} width={80} height={80} className="img"/>
            })
        }
        else {
            for (var i = 0; i < acceptedFiles.length; i++) {
                if (acceptedFiles[i].size >= 400 * 1024) {
                    b = false;
                    break;
                }
            }
            if (a && b) {
                localStorage.removeItem("isSelectLabel");
                if (!localStorage.getItem("isSelectLabel")) {
                    localStorage.setItem("isSelectImage", "0");
                    const list = [];
                    for (var i = 0; i < acceptedFiles.length; i++) {
                        list.push(0);
                    }
                    localStorage.setItem("isSelectLabel", JSON.stringify(list));
                }
                return <>
                    <input {...getInputProps()} />
                    <img
                        draggable={false}
                        key={1}
                        alt={"uploaded"}
                        src={"ico/box-closed.png"}
                    />
                    <p key={2} className="extraBold">{locale === "en" ? acceptedFiles.length + " images loaded" : (locale === "zh-Hans" ? "已加载" + acceptedFiles.length + "张图片" : "已加載" + acceptedFiles.length + "張圖片")}</p>
                </>;
            }
            else {
                return <>
                    <input {...getInputProps()} />
                    <img
                        draggable={false}
                        alt={"upload"}
                        src={"ico/box-opened.png"}
                    />
                    <p className="extraBold">{locale === "en" ? "Drop images" : (locale === "zh-Hans" ? "拖拽图片" : "拖拽圖片")}</p>
                    <p>{locale === "en" ? "or" : (locale === "zh-Hans" ? "或" : "或")}</p>
                    <p className="extraBold">{locale === "en" ? "Click here to select them" : (locale == "zh-Hans" ? "单击此处选择图片" : "單擊此處選擇圖片")}</p>
                </>;
            }
        }
    };

    return (
        <div className="ImagesDropZone">
            { number > 0 && name && type === "7" ? <div> {locale === "en" ? number + " images loaded" : (locale === "zh-Hans" ? "已加载" + number + "张图片" : "已加載" + number + "張圖片")} </div> : ''}
            <div {...getRootProps({ className: 'DropZone' })} title="上传图片进行标记">
                {number > 0 && name && type === "7" ? <div className="flex_image">{getDropZoneContent()}</div> : getDropZoneContent()}
            </div>
            <div className="upload_img">

            </div>
            <div className="DropZoneButtons">
                <TextButton
                    label={locale == "en" ? "Start to mark up" : (locale == "zh-Hans" ? "开始标记" : "開始標記")}
                    isDisabled={(type === '1' || type === '5' || (type === '7' && name && number > 0)) ? false : ((type === '7' && name && number == 0) ? true : (!acceptedFiles.length || !a))}
                    onClick={() => startEditor(ProjectType.OBJECT_DETECTION)}
                    title={(groupid || acceptedFiles.length > 0 || number > 0) && (locale == "en" ? "Start to mark up" : (locale == "zh-Hans" ? "图片上传完毕，开始标记" : "圖片上傳完畢，開始標記"))}
                />
            </div>

            {/* {
                type && parseInt(type) <= 7 ? <div className="DropZoneButtons"><TextButton
                    label={locale == "en" ? "Start to mark up" : (locale == "zh-Hans" ? "开始标记" : "開始標記")}
                    isDisabled={(groupid || parseInt(type) == 7) ? !a : (!acceptedFiles.length || !a)}
                    onClick={() => startEditor(ProjectType.OBJECT_DETECTION)}
                    title={(groupid || acceptedFiles.length > 0) && (locale == "en" ? "Start to mark up" : (locale == "zh-Hans" ? "图片上传完毕，开始标记" : "圖片上傳完畢，開始標記"))}
                /></div> : (type && parseInt(type) > 7 ? <div className="DropZoneButtons"><TextButton
                    label={locale == "en" ? "Start to classify" : (locale == "zh-Hans" ? "开始分类" : "開始分類")}
                    isDisabled={groupid ? !a : (!acceptedFiles.length || !a)}
                    onClick={() => startEditor(ProjectType.IMAGE_RECOGNITION)}
                    title={(groupid || acceptedFiles.length > 0) && (locale == "en" ? "Start to classify" : (locale == "zh-Hans" ? "图片上传完毕，开始分类" : "圖片上傳完畢，開始分類"))}
                /></div> : '')}
            {
                !type ? <div className="DropZoneButtons"><TextButton
                    label={locale == "en" ? "Start to mark up" : (locale == "zh-Hans" ? "开始标记" : "開始標記")}
                    isDisabled={groupid ? !a : (!acceptedFiles.length || !a)}
                    onClick={() => startEditor(ProjectType.OBJECT_DETECTION)}
                    externalClassName="half"
                    title={(groupid || acceptedFiles.length > 0) && (locale == "en" ? "Start to mark up" : (locale == "zh-Hans" ? "图片上传完毕，开始标记" : "圖片上傳完畢，開始標記"))}
                /><TextButton
                        label={locale == "en" ? "Start to classify" : (locale == "zh-Hans" ? "开始分类" : "開始分類")}
                        isDisabled={groupid ? !a : (!acceptedFiles.length || !a)}
                        onClick={() => startEditor(ProjectType.IMAGE_RECOGNITION)}
                        externalClassName="half"
                        title={(groupid || acceptedFiles.length > 0) && (locale == "en" ? "Start to classify" : (locale == "zh-Hans" ? "图片上传完毕，开始分类" : "圖片上傳完畢，開始分類"))}
                    /></div> : ''
            } */}

            <div className="alert alert-info tip">
                <p>{locale === "en" ? "Notes:" : (locale === "zh-Hans" ? "注意事项：" : "注意事項：")}</p>
                <p>{locale === "en" ? "1.Please make sure all pictures are within 45m in size" : (locale == "zh-Hans" ? "1.请确保全部图片大小不超过45MB" : "1.請確保全部圖片大小不超過45MB")}</p>
                <p>{locale === "en" ? "2.If the size of each image is too large, please use the image compression tool to compress and upload the tag" : (locale == "zh-Hans" ? "2.若每张图片大小过大，请使用图片压缩工具进行压缩后再上传标记" : "2.若每張圖片大小過大，請使用圖片壓縮工具進行壓縮後再上載標記")}</p>
                {/* <p>{locale === "en" ? "2.If the size of each image is too large, please use the image compression tool to compress it before uploading the tag or classification" : (locale == "zh-Hans" ? "2.若每张图片大小过大，请使用图片压缩工具进行压缩后再上传标记或分类" : "2.若每張圖片大小過大，請使用圖片壓縮工具進行壓縮後再上載標記或分類")}</p> */}
                <p>{locale === "en" ? "3. Compression course: " : (locale == "zh-Hans" ? "3.压缩教程：" : "3.壓縮教程：")}<a className="aa" href="https://jingyan.baidu.com/article/47a29f24827fa3c014239912.html" target="_blank">https://jingyan.baidu.com/article/47a29f24827fa3c014239912.html</a></p>
            </div>
            {!a ? <div className="alert alert-danger error">{locale === "en" ? "The image suffix should be " : (locale === "zh-Hans" ? "图片后缀应该为" : "圖片後綴應該爲")}<strong>jpg</strong></div> : <div></div>}
            {!b ? <div className="alert alert-danger error">{locale === "en" ? "Please make sure that the size of each picture does not more than " : (locale === "zh-Hans" ? "请确保每张图片大小不超过" : "請確保每張圖片大小不超過")}<strong>400KB</strong></div> : <div></div>}
        </div>
    )
};

const mapDispatchToProps = {
    updateActiveImageIndex,
    addImageData,
    updateProjectData,
    updateActivePopupType
};

const mapStateToProps = (state: AppState) => ({
    projectData: state.general.projectData
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ImagesDropZone);