import {updateActivePopupType} from "../../store/general/actionCreators";
import {PopupWindowType} from "../enums/PopupWindowType";
import {store} from "../../index";


export type DropDownMenuNode = {
    name: string
    description?: string
    imageSrc: string
    imageAlt: string
    disabled: boolean
    onClick?: () => void
    children?: DropDownMenuNode[],
    title: string
}

export const EnDropDownMenuData: DropDownMenuNode[] = [
    {
        name: "Actions",
        imageSrc: "ico/actions.png",
        imageAlt: "actions",
        disabled: false,
        title:"",
        children: [
            {
                name: "Edit Labels",
                description: "Modify labels list",
                imageSrc: "ico/tags.png",
                imageAlt: "labels",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.UPDATE_LABEL)),
                title:"Edit Labels"
            },
            {
                name: "Import Images",
                description: "Load more images",
                imageSrc: "ico/camera.png",
                imageAlt: "images",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.IMPORT_IMAGES)),
                title:"Import Images"
            },
            {
                name: "Import Annotations",
                description: "Import annotations from file",
                imageSrc: "ico/import-labels.png",
                imageAlt: "import-labels",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.IMPORT_ANNOTATIONS)),
                title:"Import Annotations"
            },
            {
                name: "Upload tag file",
                description: "Export annotations to file",
                imageSrc: "ico/export-labels.png",
                imageAlt: "export-labels",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.EXPORT_ANNOTATIONS)),
                title:"Upload tag file"
            },
            {
                name: "Export Annotations",
                description: "Export annotations to file",
                imageSrc: "ico/export-labels.png",
                imageAlt: "export-labels",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.EXPORT_ANNOTATION)),
                title:"Export Annotations"
            }
        ]
    }
    /*
    {
        name: "More",
        imageSrc: "ico/more.png",
        imageAlt: "more",
        disabled: false,
        children: [
            {
                name: "Documentation",
                description: "Coming soon",
                imageSrc: "ico/documentation.png",
                imageAlt: "documentation",
                disabled: true
            },
            {
                name: "Bugs and Features",
                description: "Coming soon",
                imageSrc: "ico/bug.png",
                imageAlt: "bug",
                disabled: false,
                onClick: () => window.open("https://github.com/SkalskiP/make-sense/issues", "_blank")
            }
        ]
    }
    */
]

export const zh_HansDropDownMenuData: DropDownMenuNode[] = [
    {
        name: "操作",
        imageSrc: "ico/actions.png",
        imageAlt: "actions",
        disabled: false,
        title:"",
        children: [
            {
                name: "编辑标签",
                description: "Modify labels list",
                imageSrc: "ico/tags.png",
                imageAlt: "labels",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.UPDATE_LABEL)),
                title:"对标签进行修改"
            },
            {
                name: "导入图片",
                description: "Load more images",
                imageSrc: "ico/camera.png",
                imageAlt: "images",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.IMPORT_IMAGES)),
                title:"导入图片"
            },
            {
                name: "导入标记文件",
                description: "Import annotations from file",
                imageSrc: "ico/import-labels.png",
                imageAlt: "import-labels",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.IMPORT_ANNOTATIONS)),
                title:"导入标记文件"
            },
            {
                name: "上传标记文件",
                description: "Export annotations to file",
                imageSrc: "ico/export-labels.png",
                imageAlt: "export-labels",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.EXPORT_ANNOTATIONS)),
                title:"上传标记文件"
            },
            {
                name: "导出标记文件",
                description: "Export annotations to file",
                imageSrc: "ico/export-labels.png",
                imageAlt: "export-labels",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.EXPORT_ANNOTATION)),
                title:"导出标记文件"
            }
        ]
    }
    /*
    {
        name: "More",
        imageSrc: "ico/more.png",
        imageAlt: "more",
        disabled: false,
        children: [
            {
                name: "Documentation",
                description: "Coming soon",
                imageSrc: "ico/documentation.png",
                imageAlt: "documentation",
                disabled: true
            },
            {
                name: "Bugs and Features",
                description: "Coming soon",
                imageSrc: "ico/bug.png",
                imageAlt: "bug",
                disabled: false,
                onClick: () => window.open("https://github.com/SkalskiP/make-sense/issues", "_blank")
            }
        ]
    }
    */
]

export const zh_HantDropDownMenuData: DropDownMenuNode[] = [
    {
        name: "操作",
        imageSrc: "ico/actions.png",
        imageAlt: "actions",
        disabled: false,
        title:"",
        children: [
            {
                name: "編輯標籤",
                description: "Modify labels list",
                imageSrc: "ico/tags.png",
                imageAlt: "labels",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.UPDATE_LABEL)),
                title:"對標籤進行修改"
            },
            {
                name: "導入圖片",
                description: "Load more images",
                imageSrc: "ico/camera.png",
                imageAlt: "images",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.IMPORT_IMAGES)),
                title:"導入圖片"
            },
            {
                name: "導入標記文件",
                description: "Import annotations from file",
                imageSrc: "ico/import-labels.png",
                imageAlt: "import-labels",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.IMPORT_ANNOTATIONS)),
                title:"導入標記圖片"
            },
            {
                name: "上傳標記文件",
                description: "Export annotations to file",
                imageSrc: "ico/export-labels.png",
                imageAlt: "export-labels",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.EXPORT_ANNOTATIONS)),
                title: "上傳標記文件"
            },
            {
                name: "導出標記文件",
                description: "Export annotations to file",
                imageSrc: "ico/export-labels.png",
                imageAlt: "export-labels",
                disabled: false,
                onClick: () => store.dispatch(updateActivePopupType(PopupWindowType.EXPORT_ANNOTATION)),
                title:"導出標記文件"
            }
        ]
    }
    /*
    {
        name: "More",
        imageSrc: "ico/more.png",
        imageAlt: "more",
        disabled: false,
        children: [
            {
                name: "Documentation",
                description: "Coming soon",
                imageSrc: "ico/documentation.png",
                imageAlt: "documentation",
                disabled: true
            },
            {
                name: "Bugs and Features",
                description: "Coming soon",
                imageSrc: "ico/bug.png",
                imageAlt: "bug",
                disabled: false,
                onClick: () => window.open("https://github.com/SkalskiP/make-sense/issues", "_blank")
            }
        ]
    }
    */
]

