import React, { useState } from 'react'
import './InsertLabelNamesPopup.scss'
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup";
import { PopupWindowType } from "../../../data/enums/PopupWindowType";
import { updateLabelNames } from "../../../store/labels/actionCreators";
import { updateActivePopupType } from "../../../store/general/actionCreators";
import { AppState } from "../../../store";
import { connect } from "react-redux";
import Scrollbars from 'react-custom-scrollbars';
import TextInput from "../../Common/TextInput/TextInput";
import { ImageButton } from "../../Common/ImageButton/ImageButton";
import uuidv4 from 'uuid/v4';
import { LabelName } from "../../../store/labels/types";
import { LabelUtil } from "../../../utils/LabelUtil";
import { LabelsSelector } from "../../../store/selectors/LabelsSelector";
import { LabelActions } from "../../../logic/actions/LabelActions";
import { ProjectType } from "../../../data/enums/ProjectType";
import axios from 'axios';
import { map } from 'lodash';

interface IProps {
    projectType: ProjectType;
    updateActivePopupType: (activePopupType: PopupWindowType) => any;
    updateLabelNames: (labels: LabelName[]) => any;
    isUpdate: boolean;
}

const getQueryVariable = (variable: string) => {
    // 获取url参数
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}


const groupid = getQueryVariable('groupid');
const groupnum = getQueryVariable('groupnum');
const name = getQueryVariable('name');

const InsertLabelNamesPopup: React.FC<IProps> = (
    {
        projectType,
        updateActivePopupType,
        updateLabelNames,
        isUpdate
    }) => {
    const initialLabels = LabelUtil.convertLabelNamesListToMap(LabelsSelector.getLabelNames());
    const [labelNames, setLabelNames] = useState(initialLabels);

    var locale = localStorage.getItem("locale");

    const addHandle = (value:string) => {
        const newLabelNames = { ...labelNames, [uuidv4()]: value };
        setLabelNames(newLabelNames);
    };

    
    if(groupid&&groupnum&&name && Object.keys(labelNames).length ===0 ){
        // https://traininguser-api.cocorobo.cn
        axios.get(`//traininguser-api.cocorobo.cn/names/${name}/${name}/${name}.names`).then(res=>{
            // console.log(res.data.split(','))
            if(res.data && res.status === 200){
                // addHandle()
                let arr = res.data.split(',')[0].split('\n')
                // res.data.split(',').map(r=>{
                    
                // })
                let newLabelNames = {}
                for(var i =0;i<arr.length;i++){
                    // addHandle(arr[i])
                    newLabelNames = {...newLabelNames,[uuidv4()]:arr[i]}
                }
                setLabelNames(newLabelNames);
            }
        })
    }

    const deleteHandle = (key: string) => {
        const newLabelNames = { ...labelNames };
        delete newLabelNames[key];
        setLabelNames(newLabelNames);
    };

    const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            addHandle('');
        }
    }

    const labelInputs = Object.keys(labelNames).map((key: string) => {
        return <div className="LabelEntry" key={key}>
            <TextInput
                key={key}
                value={labelNames[key]}
                isPassword={false}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange(key, event.target.value)}
                label={locale == "en" ? "Insert label":(locale == "zh-Hans" ? "增加标签" : "增加標籤")}
                onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => handleKeyUp(event)}
            />
            <ImageButton
                image={"ico/trash.png"}
                imageAlt={"remove_label"}
                buttonSize={{ width: 30, height: 30 }}
                onClick={() => deleteHandle(key)}
            />
        </div>
    });

    const onChange = (key: string, value: string) => {
        const re = /[^\w\.\/]/ig;
        if (value === '' || !re.test(value)) {
            const newLabelNames = { ...labelNames, [key]: value };
            setLabelNames(newLabelNames);
        }
    };

    

    const onCreateAccept = () => {
        const labelNamesList: string[] = extractLabelNamesList();
        if (labelNamesList.length > 0) {
            updateLabelNames(LabelUtil.convertMapToLabelNamesList(labelNames));
        }
        updateActivePopupType(null);
    };

    const onUpdateAccept = () => {
        const labelNamesList: string[] = extractLabelNamesList();
        const updatedLabelNamesList: LabelName[] = LabelUtil.convertMapToLabelNamesList(labelNames);
        const missingIds: string[] = LabelUtil.labelNamesIdsDiff(LabelsSelector.getLabelNames(), updatedLabelNamesList);
        LabelActions.removeLabelNames(missingIds);
        if (labelNamesList.length > 0) {
            updateLabelNames(LabelUtil.convertMapToLabelNamesList(labelNames));
            updateActivePopupType(null);
        }
    };

    const onCreateReject = () => {
        updateActivePopupType(PopupWindowType.LOAD_LABEL_NAMES);
    };

    const onUpdateReject = () => {
        updateActivePopupType(null);
    };


    const extractLabelNamesList = (): string[] => {
        return Object.values(labelNames).filter((value => !!value)) as string[];
    };

    const renderContent = () => {
        

        return (<div className="InsertLabelNamesPopup">
            <div className="LeftContainer">
                <ImageButton
                    image={"ico/plus.png"}
                    imageAlt={"plus"}
                    buttonSize={{ width: 40, height: 40 }}
                    padding={25}
                    onClick={()=>addHandle('')}
                />
            </div>
            <div className="RightContainer">
                <div className="Message">
                    {
                        isUpdate ?
                            (locale == "en" ? "You can now edit the label names you use to describe the objects in the photos. Use the + " +
                                "button to add a new empty text field." : (locale == "zh-Hans" ? "现在，您可以编辑用于描述照片中对象的标签名称。使用" +
                                "按钮添加一个新的空文本字段。": "現在，您可以編輯用於描述照片中對象的標籤名稱。使用" + "按鈕添加一個新的空文本字段。"))
                            :
                            (locale == "en" ? "Before you start, you can create a list of labels you plan to assign to objects in your " +
                                "project(Note: please use English instead of Chinese). You can also choose to skip that part for now and define label names as you go." : (locale == "zh-Hans" ? "在开始之前，您可以创建计划分配给项目中的对象的标签列表(注：不能使用中文，请使用英文)。" +
                                "您还可以选择暂时跳过该部分，并在使用时定义标签名称。": "在開始之前，您可以創建計劃分配給項目中的對象的標籤列表(注：不能使用中文，請使用英文)。" + "您還可以選擇暫時跳過該部分，並在使用時定義標籤名稱。"))
                    }
                </div>
                <div className="LabelsContainer">
                    {Object.keys(labelNames).length !== 0 ? <Scrollbars>
                        <div
                            className="InsertLabelNamesPopupContent"
                        >
                            {labelInputs}
                        </div>
                    </Scrollbars> :
                        <div
                            className="EmptyList"
                            onClick={()=>addHandle('')}
                            title={locale == "en" ? "Click Create Tag tag or Load tag":(locale == "zh-Hans" ? "点击创建标记标签或加载标签" : "點擊創建標記標籤或加載標籤")}
                        >
                            <img
                                draggable={false}
                                alt={"upload"}
                                src={"ico/type-writer.png"}
                            />
                            <p className="extraBold">{locale == "en" ? "Your label list is empty":(locale == "zh-Hans" ? "您的标签列表为空" : "您的標籤列表為空")}</p>
                        </div>}
                </div>
            </div>
        </div>);
    };

    return (
        <GenericYesNoPopup
            title={isUpdate ? (locale == "en" ? "Edit labels" : (locale == "zh-Hans" ? "编辑标签" : "編輯標籤")) : (locale == "en" ? "Create labels" : (locale == "zh-Hans" ? "创建标签" : "創建標籤"))}
            renderContent={renderContent}
            acceptLabel={isUpdate ? (locale == "en" ? "Accept" :(locale == "zh-Hans" ? "确认" : "確認")) : (locale == "en" ? "Start project":(locale == "zh-Hans" ? "开始项目" : "開始項目"))}
            onAccept={isUpdate ? onUpdateAccept : onCreateAccept}
            rejectLabel={isUpdate ? (locale == "en" ? "Cancel" :(locale == "zh-Hans" ? "取消" : "取消")) : ''}
            onReject={isUpdate ? onUpdateReject : onCreateReject}
        />)
};



const mapDispatchToProps = {
    updateActivePopupType,
    updateLabelNames
};

const mapStateToProps = (state: AppState) => ({
    projectType: state.general.projectData.type
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InsertLabelNamesPopup);
