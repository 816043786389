import {LabelType} from "../enums/LabelType";
import {ProjectType} from "../enums/ProjectType";


export interface ILabelToolkit {
    labelType: LabelType;
    headerText: string;
    imageSrc: string;
    imageAlt: string;
    projectType: ProjectType;
}

export const EnLabelToolkitData: ILabelToolkit[] = [
    {
        labelType: LabelType.IMAGE_RECOGNITION,
        headerText: "Label List",
        imageSrc: "ico/object.png",
        imageAlt: "object",
        projectType: ProjectType.IMAGE_RECOGNITION,
    },
    {
        labelType: LabelType.RECT,
        headerText: "Label List",
        imageSrc: "ico/rectangle.png",
        imageAlt: "rectangle",
        projectType: ProjectType.OBJECT_DETECTION,
    }/*,
    {
        labelType: LabelType.POINT,
        headerText: "Point",
        imageSrc: "ico/point.png",
        imageAlt: "point",
        projectType: ProjectType.OBJECT_DETECTION,
    },
    {
        labelType: LabelType.LINE,
        headerText: "Line",
        imageSrc: "ico/line.png",
        imageAlt: "line",
        projectType: ProjectType.OBJECT_DETECTION,
    },
    {
        labelType: LabelType.POLYGON,
        headerText: "Polygon",
        imageSrc: "ico/polygon.png",
        imageAlt: "polygon",
        projectType: ProjectType.OBJECT_DETECTION,
    },*/
];

export const zh_HansLabelToolkitData: ILabelToolkit[] = [
    {
        labelType: LabelType.IMAGE_RECOGNITION,
        headerText: "标签列表",
        imageSrc: "ico/object.png",
        imageAlt: "object",
        projectType: ProjectType.IMAGE_RECOGNITION,
    },
    {
        labelType: LabelType.RECT,
        headerText: "标签列表",
        imageSrc: "ico/rectangle.png",
        imageAlt: "rectangle",
        projectType: ProjectType.OBJECT_DETECTION,
    }
];

export const zh_HantLabelToolkitData: ILabelToolkit[] = [
    {
        labelType: LabelType.IMAGE_RECOGNITION,
        headerText: "標籤陣列",
        imageSrc: "ico/object.png",
        imageAlt: "object",
        projectType: ProjectType.IMAGE_RECOGNITION,
    },
    {
        labelType: LabelType.RECT,
        headerText: "標籤陣列",
        imageSrc: "ico/rectangle.png",
        imageAlt: "rectangle",
        projectType: ProjectType.OBJECT_DETECTION,
    }
];