var locale = localStorage.getItem("locale");

export class YOLOAnnotationsLoadingError extends Error {
    constructor(message) {
        super(message);
        this.name = "YOLOAnnotationsLoadingError";
    }
}

export class YOLOLabelsReadingError extends YOLOAnnotationsLoadingError {
    constructor() {
        super(locale == "en" ? "Unexpected error occurred during reading label names from labels.txt file" : (locale == "zh-Hans" ? "从labels.txt文件读取标签名称期间发生意外错误" : "從labels.txt文件讀取標籤名稱期間發生意外錯誤"));
        this.name = "YOLOLabelsLoadingError";
    }
}

export class NoLabelNamesFileProvidedError extends YOLOAnnotationsLoadingError {
    constructor() {
        super(locale == "en" ? "For YOLO labels to be loaded correctly, labels.txt file is required" : (locale == "zh-Hans" ? "请创建一个 labels.txt 文本文件并随同你的标记文件一起上传" : "請創建一個 labels.txt 文本文件並隨同你的標記文件一起上載"));
        this.name = "NoLabelNamesFileProvidedError";
    }
}

export class LabelNamesNotUniqueError extends YOLOAnnotationsLoadingError {
    constructor() {
        super(locale == "en" ? "Label names listed in labels.txt file should be unique" : (locale == "zh-Hans" ? "labels.txt文件中列出的标签名称应该是唯一的" : "labels.txt文件中列出的標籤名稱應該是唯一的"));
        this.name = "LabelNamesNotUniqueError";
    }
}

export class AnnotationsParsingError extends YOLOAnnotationsLoadingError {
    constructor(imageName: string) {
        super(locale == "en" ? `Unexpected error occurred during parsing of ${imageName} annotations file` : (locale == "zh-Hans" ? `解析${imageName}标记文件期间发生意外错误` : `解析${imageName}標記文件期間發生意外錯誤`));
        this.name = "AnnotationsParsingError";
    }
}