import React from 'react'
import './LoadMoreImagesPopup.scss'
import {AppState} from "../../../store";
import {connect} from "react-redux";
import {addImageData} from "../../../store/labels/actionCreators";
import {GenericYesNoPopup} from "../GenericYesNoPopup/GenericYesNoPopup";
import {useDropzone} from "react-dropzone";
import {ImageData} from "../../../store/labels/types";
import {AcceptedFileType} from "../../../data/enums/AcceptedFileType";
import {PopupActions} from "../../../logic/actions/PopupActions";
import {ImageDataUtil} from "../../../utils/ImageDataUtil";

interface IProps {
    addImageData: (imageData: ImageData[]) => any;
}

const LoadMoreImagesPopup: React.FC<IProps> = ({addImageData}) => {
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        accept: AcceptedFileType.IMAGE
    });

    const onAccept = () => {
        if (acceptedFiles.length > 0) {
            addImageData(acceptedFiles.map((fileData:File) => ImageDataUtil.createImageDataFromFileData(fileData,null)));
            PopupActions.close();
        }
    };

    const onReject = () => {
        PopupActions.close();
    };

    var locale = localStorage.getItem("locale");
    var a = true;
    const getDropZoneContent = () => {
        if (acceptedFiles.length === 0)
            return <>
                <input {...getInputProps()} />
                <img
                    draggable={false}
                    alt={"upload"}
                    src={"ico/box-opened.png"}
                />
                <p className="extraBold">{locale == "en" ? "Add new images" : (locale == "zh-Hans" ? "添加新图片" : "添加新圖片")}</p>
                <p>{locale == "en" ? "or" : (locale == "zh-Hans" ? "或" : "或")}</p>
                <p className="extraBold">{locale == "en" ? "Click here to select them" : (locale == "zh-Hans" ? "单击此处选择图片" : "單擊此處選擇圖片")}</p>
            </>;
        else if ((acceptedFiles.length === 1 && acceptedFiles[0].name.split(".")[1] == "jpg") || (acceptedFiles.length === 1 && acceptedFiles[0].name.split(".")[1] == "JPG"))
            return <>
                <img
                    draggable={false}
                    alt={"uploaded"}
                    src={"ico/box-closed.png"}
                />
                <p className="extraBold">{locale == "en"?"1 new image loaded":(locale == "zh-Hans"?"已加载1张新图片":"已加載1張新圖片")}</p>
            </>;
        else
            for (var i = 0; i < acceptedFiles.length; i++) {
                if (acceptedFiles[i].name.split(".")[1] != "jpg" && acceptedFiles[i].name.split(".")[1] != "JPG") {
                    a = false;
                    break;
                }
            }
            if (a)
                return <>
                    <img
                        draggable={false}
                        key={1}
                        alt={"uploaded"}
                        src={"ico/box-closed.png"}
                    />
                    <p key={2} className="extraBold">{locale == "en"? acceptedFiles.length + " new images loaded":(locale == "zh-Hans"?"已加载" + acceptedFiles.length + "张新图片":"已加載" + acceptedFiles.length + "張新圖片")}</p>
                </>;
            else
                return <>
                    <input {...getInputProps()} />
                    <img
                        draggable={false}
                        alt={"upload"}
                        src={"ico/box-opened.png"}
                    />
                    <p className="extraBold">{locale == "en" ? "Add new images" : (locale == "zh-Hans" ? "添加新图片" : "添加新圖片")}</p>
                    <p>{locale == "en" ? "or" : (locale == "zh-Hans" ? "或" : "或")}</p>
                    <p className="extraBold">{locale == "en" ? "Click here to select them" : (locale == "zh-Hans" ? "单击此处选择图片" : "單擊此處選擇圖片")}</p>
                    {!a?<div><p>{locale == "en" ? "The image suffix should be jpg" : (locale == "zh-Hans" ? "图片后缀应该为jpg" : "圖片後綴應該爲jpg")}</p></div>:<div></div>}
                </>;
    };

    const renderContent = () => {
        return(<div className="LoadMoreImagesPopupContent">
            <div {...getRootProps({className: 'DropZone'})}>
                {getDropZoneContent()}
            </div>
        </div>);
    };

    return(
        <GenericYesNoPopup
            title={locale == "en"? "Load more images":(locale == "zh-Hans"?"加载更多图片":"加載更多圖片")}
            renderContent={renderContent}
            acceptLabel={locale == "en"? "Load":(locale == "zh-Hans"?"加载":"加載")}
            disableAcceptButton={acceptedFiles.length < 1}
            onAccept={onAccept}
            rejectLabel={locale == "en" ? "Cancel":(locale == "zh-Hans" ? "取消" : "取消")}
            onReject={onReject}
        />
    );
};

const mapDispatchToProps = {
    addImageData
};

const mapStateToProps = (state: AppState) => ({});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoadMoreImagesPopup);