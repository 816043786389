import React, {useState} from 'react'
import './ExportLabelPopup.scss'
import {AnnotationFormatType} from "../../../data/enums/AnnotationFormatType";
import {RectLabelsExporter} from "../../../logic/export/RectLabelsExporter";
import {LabelType} from "../../../data/enums/LabelType";
import {ILabelFormatData} from "../../../interfaces/ILabelFormatData";
import {PointLabelsExporter} from "../../../logic/export/PointLabelsExport";
import {PolygonLabelsExporter} from "../../../logic/export/polygon/PolygonLabelsExporter";
import {PopupActions} from "../../../logic/actions/PopupActions";
import {LineLabelsExporter} from "../../../logic/export/LineLabelExport";
import {TagLabelsExporter} from "../../../logic/export/TagLabelsExport";
import GenericLabelTypePopup from "../GenericLabelTypePopup/GenericLabelTypePopup";
import {EnExportFormatData, zh_HansEnExportFormatData, zh_HantEnExportFormatData} from "../../../data/ExportFormatDataUpload";
import {AppState} from "../../../store";
import {connect} from "react-redux";

interface IProps {
    activeLabelType: LabelType,
}

// http://localhost/?groupid=31fd3824-7995-11ec-aba0-0aee66466c7b&groupnum=1
const getQueryVariable = (variable: string) => {
    // 获取url参数
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}

const type = getQueryVariable('type');

const ExportLabelPopup: React.FC <IProps> = ({activeLabelType}) => {
    const [labelType, setLabelType] = useState(activeLabelType);
    const [exportFormatType, setExportFormatType] = useState(null);

    const onAccept = (labelType: LabelType) => {
        switch (labelType) {
            case LabelType.RECT:
                RectLabelsExporter.export(exportFormatType);
                break;
            case LabelType.POINT:
                PointLabelsExporter.export(exportFormatType);
                break;
            case LabelType.LINE:
                LineLabelsExporter.export(exportFormatType);
                break;
            case LabelType.POLYGON:
                PolygonLabelsExporter.export(exportFormatType);
                break;
            case LabelType.IMAGE_RECOGNITION:
                TagLabelsExporter.export(exportFormatType);
                break;
        }
        PopupActions.close();
    };

    const onReject = (labelType: LabelType) => {
        PopupActions.close();
    };

    var locale = localStorage.getItem("locale");

    const onSelect = (exportFormatType: AnnotationFormatType) => {
        setExportFormatType(exportFormatType);
    };

    const getOptions = (exportFormatData: ILabelFormatData[]) => {
        return exportFormatData.map((entry: ILabelFormatData) => {
            return <div
                className="OptionsItem"
                onClick={() => onSelect(entry.type)}
                key={entry.type}
            >
                {entry.type === exportFormatType ?
                    <img
                        draggable={false}
                        src={"ico/checkbox-checked.png"}
                        alt={"checked"}
                    /> :
                    <img
                        draggable={false}
                        src={"ico/checkbox-unchecked.png"}
                        alt={"unchecked"}
                    />}
                {entry.label}
            </div>
        })
    };

    const renderInternalContent = (labelType: LabelType) => {
        var str = '';
        if(type === "1" || type === "0" || type === "3") {
            setExportFormatType('YOLO_UPLOAD')
            str = "YOLO"
        }
        if(type === "5" || type === "4" || type === "7") {
            setExportFormatType('VOC_UPLOAD')
            str = "VOC XML"
        }
        // setExportFormatType('YOLO')
        return [
            <div className="Message" style={{border:'none'}}>
                {locale === "en" ? "Upload the " + str + ". Zip file package ." : (locale === "zh-Hans" ? "上传" + str + "文件格式.zip文件包。" : "上傳" + str + "文件格式.zip文件包。")}
                <p style={{color:'white'}}>（{locale === "en" ? "*Note: If you upload a picture of the wrong format or name, we will delete the picture or force conversion." : (locale === "zh-Hans" ? 
                "*注：如果您上传的某张图片格式或者命名有误，我们将删除该图片或强制进行转换。" : "*注：如果您上傳的某張圖片格式或者命名，我們誤將刪除該圖片或強制進行轉換。")}）</p>
                
            </div>,
            // <div className="Options">
            //     {locale == "en" ? getOptions(EnExportFormatData[labelType]): (locale == "zh-Hans" ? getOptions(zh_HansEnExportFormatData[labelType]) : getOptions(zh_HantEnExportFormatData[labelType]))}
            // </div>
        ]
    }

    const onLabelTypeChange = (labelType: LabelType) => {
        setLabelType(labelType);
        setExportFormatType(null);
    }

    return(
        <GenericLabelTypePopup
            activeLabelType={labelType}
            title={locale == "en" ? `Upload ${labelType.toLowerCase()} annotations`:(locale == "zh-Hans" ? `上传${labelType.toLowerCase()}标记文件` : `上傳${labelType.toLowerCase()}標記文件`)}
            onLabelTypeChange={onLabelTypeChange}
            acceptLabel={locale == "en" ? "Confirm":(locale == "zh-Hans" ? "确认" : "確認")}
            onAccept={onAccept}
            // disableAcceptButton={!exportFormatType}
            rejectLabel={locale == "en" ? "Cancel":(locale == "zh-Hans" ? "取消" : "取消")}
            onReject={onReject}
            renderInternalContent={renderInternalContent}
        />
    )
};

const mapDispatchToProps = {};

const mapStateToProps = (state: AppState) => ({
    activeLabelType: state.labels.activeLabelType,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportLabelPopup);