import { AnnotationFormatType } from "../../data/enums/AnnotationFormatType";
import { ImageData, LabelName, LabelRect } from "../../store/labels/types";
import { ImageRepository } from "../imageRepository/ImageRepository";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { LabelsSelector } from "../../store/selectors/LabelsSelector";
import { XMLSanitizerUtil } from "../../utils/XMLSanitizerUtil";
import { ExporterUtil } from "../../utils/ExporterUtil";
import { GeneralSelector } from "../../store/selectors/GeneralSelector";
import { findIndex, findLast } from "lodash";
import axios from 'axios';
import { data, imag, image } from "@tensorflow/tfjs";
// import {getFinalLabelsGlobal} from '../../views/EditorView/SideNavigationBar/LabelInputField/LabelInputField';
import { PopupWindowType } from "../../data/enums/PopupWindowType";
import { url } from "inspector";
import { CustomCursorStyle } from "../../data/enums/CustomCursorStyle";


const getQueryVariable = (variable: string) => {
    // 获取url参数
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
}


const groupid = getQueryVariable('groupid');
const groupnum = getQueryVariable('groupnum');
const userid = getQueryVariable('userid');
const type = getQueryVariable('type');
const name = getQueryVariable('name');
export class RectLabelsExporter {
    public static export(exportFormatType: AnnotationFormatType): void {
        switch (exportFormatType) {
            case AnnotationFormatType.YOLO:
                RectLabelsExporter.exportAsYOLO();
                break;
            case AnnotationFormatType.YOLO_UPLOAD:
                RectLabelsExporter.exportAsYOLOUpload();
                break;
            case AnnotationFormatType.VOC:
                RectLabelsExporter.exportAsVOC();
                break;
            case AnnotationFormatType.VOC_UPLOAD:
                RectLabelsExporter.exportAsVOCUpload();
                break;
            case AnnotationFormatType.CSV:
                RectLabelsExporter.exportAsCSV();
                break;
            default:
                return;
        }
    }



    private static exportAsYOLOUpload(): void {
        var locale = localStorage.getItem("locale");
        var a = true;
        let noNum = 0
        const list = JSON.parse(localStorage.getItem("isSelectLabel"));
        for (var i = 0; i < LabelsSelector.getImagesData().length; i++) {
            if (list[i] === 0) {

                // eslint-disable-next-line no-restricted-globals
                let r = confirm('有图片未标记，是否提交')
                if (r) {
                    a = true
                } else {
                    a = false;
                }
                noNum = i + 1
                break;
            }
        }

        if (a) {
            let zip = new JSZip();

            var fileContent: string = "";

            LabelsSelector.getLabelNames()
                .forEach((labelName: LabelName) => {
                    if (labelName) {
                        try {
                            fileContent += labelName.name.toString() + "\n"
                            zip.file(ExporterUtil.getExportFileName() + "/" + ExporterUtil.getExportFileName() + ".names", fileContent);
                        } catch (error) {
                            // TODO
                            throw new Error(error);
                        }
                    }
                });

            LabelsSelector.getImagesData()
                .forEach((imageData: ImageData) => {
                    const fileContent: string = RectLabelsExporter.wrapRectLabelsIntoYOLO(imageData);
                    // console.log(fileContent);
                    if (fileContent) {
                        let fileName = null
                        if (window.location.href.indexOf('groupid') > 0 && type === "1") {
                            let first = imageData.url.lastIndexOf('/') + 1
                            let end = imageData.url.lastIndexOf('.')
                            fileName = imageData.url.slice(first, end) + '.txt'
                        } else {
                            fileName = imageData.fileData.name.replace(/\.[^/.]+$/, ".txt");
                        }
                        // const fileName: string = imageData.fileData.name.replace(/\.[^/.]+$/, ".txt");
                        // console.log(imageData.fileData.name.replace(/\.[^/.]+$/, ".txt"));
                        try {
                            zip.file(ExporterUtil.getExportFileName() + "/" + fileName, fileContent);
                            if (imageData.fileData) {
                                zip.file(ExporterUtil.getExportFileName() + "/" + imageData.fileData.name, imageData.fileData)
                            }
                        } catch (error) {
                            // TODO
                            throw new Error(error);
                        }
                    }
                });
            try {
                zip.generateAsync({ type: "blob" })
                    .then(function (content) {
                        let obj = undefined
                        obj = content
                        obj.name = `${ExporterUtil.getExportFileName()}.zip`
                        if (window.location.href.indexOf('groupid') > 0) {
                            let fileBlob = new File([obj], `${ExporterUtil.getExportFileName()}.zip`, { lastModified: Date.now() });
                            // groupid=507ce0d5-7291-11ec-a735-2a16101a060a&groupnum=1
                            let data = new FormData()
                            // console.log('fileBlob', fileBlob)
                            data.append('file', fileBlob)
                            data.append('groupid', String(groupid))
                            data.append('groupnum', String(groupnum))
                            data.append('groupname', String(name))
                            axios.post('//traininguser-api.cocorobo.cn/labelingupload/txt', data).then(res => {
                                // console.log(res.data.FunctionResponse.Info.indexOf('Successfully'))
                                if (res.data.FunctionResponse.Info.indexOf('Successfully') !== -1) {
                                    let group = {
                                        userid: userid,
                                        id: groupid,
                                        num: groupnum
                                    }
                                    axios.post("//traininguser-api.cocorobo.cn/group/updategroupdetails", group).then(d => {
                                        // console.log(d.data.FunctionResponse.length === 0)
                                        if (d.data.FunctionResponse.length === 0) {
                                            alert(locale == "en" ? "Tag uploaded successfully" : (locale == "zh-Hans" ? "上传标记成功" : "上傳標記成功"))
                                            let url = window.location.hostname.indexOf("hk") !== -1 ? "//beta.model-training.cocorobo.hk" : "//xunlian.cocorobo.cn"
                                            window.open(url, '_blank')
                                        } else {
                                            alert(d.data.FunctionResponse.error)
                                        }
                                    })
                                } else {
                                    alert(res.data.FunctionResponse.error)
                                }
                            })
                        } else if (type === '3') {
                            let fileBlob = new File([obj], `${userid}.zip`, { lastModified: Date.now() });
                            let params = new FormData();
                            params.append('id', undefined);
                            params.append('num', undefined);
                            params.append('type', 'zip');
                            params.append('code', `${userid}`);
                            params.append('file', fileBlob);
                            axios.post('//traininguser-api.cocorobo.cn/upload', params).then(res => {
                                if (res.data.FunctionResponse.Error == undefined) {
                                    let obj = {
                                        "Operation": "get_labeled_dataset_from_local",
                                        "DatasetName": userid,
                                        "DirectoryPath": res.data.FunctionResponse.Path,
                                        "Code": userid
                                    };
                                    axios.post('//traininguser-api.cocorobo.cn/operation', obj).then(d => {
                                        if (d.data.FunctionResponse.Response.Error == undefined) {
                                            alert(locale === "en" ? "Tag uploaded successfully" : (locale === "zh-Hans" ? "上传标记成功" : "上傳標記成功"))
                                            let url = window.location.hostname.indexOf("hk") !== -1 ? "//beta.model-training.cocorobo.hk/detaset" : "//xunlian.cocorobo.cn/detaset"
                                            window.open(url, '_blank')
                                        } else {
                                            alert(d.data.FunctionResponse.Response.Error)
                                        }
                                    })
                                }
                            })
                        } else {
                            saveAs(content, `${ExporterUtil.getExportFileName()}.zip`);
                        }

                    });
            } catch (error) {
                // TODO
                throw new Error(error);
            }
        }
        else {
            var str = locale === "en" ? `The ${noNum} image is unlabeled` : (locale === "zh-Hans" ? `第${noNum}张图片未标记` : `第${noNum}圖片未標記`);
            alert(str);
        }
    }

    private static wrapRectLabelsIntoYOLO(imageData: ImageData): string {
        if (imageData.labelRects.length === 0 || !imageData.loadStatus)
            return null;

        const labelNames: LabelName[] = LabelsSelector.getLabelNames();
        const image: HTMLImageElement = ImageRepository.getById(imageData.id);
        const labelRectsString: string[] = imageData.labelRects.map((labelRect: LabelRect) => {
            const labelFields = [
                findIndex(labelNames, { id: labelRect.labelId }).toString(),
                ((labelRect.rect.x + labelRect.rect.width / 2) / image.width).toFixed(6).toString(),
                ((labelRect.rect.y + labelRect.rect.height / 2) / image.height).toFixed(6).toString(),
                (labelRect.rect.width / image.width).toFixed(6).toString(),
                (labelRect.rect.height / image.height).toFixed(6).toString()
            ];
            return labelFields.join(" ")
        });
        return labelRectsString.join("\n");
    }

    private static exportAsYOLO(): void {
        // 导出
        var locale = localStorage.getItem("locale");
        var a = true;
        let noNum = 0
        const list = JSON.parse(localStorage.getItem("isSelectLabel"));
        for (var i = 0; i < LabelsSelector.getImagesData().length; i++) {
            if (list[i] === 0) {
                // eslint-disable-next-line no-restricted-globals
                let r = confirm('有图片未标记，是否提交')
                if (r) {
                    a = true
                } else {
                    a = false;
                }
                noNum = i + 1
                break;
            }
        }


        if (a) {
            let zip = new JSZip();
            var fileContent: string = "";
            LabelsSelector.getLabelNames()
                .forEach((labelName: LabelName) => {
                    if (labelName) {
                        try {
                            fileContent += labelName.name.toString() + "\n"
                            zip.file(ExporterUtil.getExportFileName() + "/" + ExporterUtil.getExportFileName() + ".names", fileContent);
                        } catch (error) {
                            // TODO
                            throw new Error(error);
                        }
                    }
                });

            LabelsSelector.getImagesData()
                .forEach((imageData: ImageData) => {
                    const fileContent: string = RectLabelsExporter.wrapRectLabelsIntoYOLO(imageData);
                    if (fileContent) {
                        if (imageData.url) {
                            let fileName = null
                            let first = imageData.url.lastIndexOf('/') + 1
                            let end = imageData.url.lastIndexOf('.')
                            fileName = imageData.url.slice(first, end) + '.txt'
                            let images = new Image();
                            images.src = imageData.url;
                            images.onload = async function () {
                                let base64 = await RectLabelsExporter.getBase64Image(images)
                                // console.log(base64)
                                let files = await RectLabelsExporter.dataURLtoFile(base64,imageData.url.slice(first, end)+ ".jpg")
                                // let data = new File([files], imageData.url.slice(first, end), { type: "image/jpg" })
                                // console.log(data, fileName)
                                try {
                                    zip.file(ExporterUtil.getExportFileName() + "/" + fileName, fileContent);
                                    zip.file(ExporterUtil.getExportFileName() + "/" + files.name, files)

                                } catch (error) {
                                    // TODO
                                    throw new Error(error);
                                }
                            }
                        } else {
                            // let fileName = null
                            // fileName = imageData.fileData.name.replace(/\.[^/.]+$/, ".txt");
                            let name1 = imageData.fileData.name.slice(0, imageData.fileData.name.lastIndexOf('.')).replace(/\./g, '');
                            let name = name1 + '.jpg'
                            try {
                                zip.file(ExporterUtil.getExportFileName() + "/" + name1 + '.txt', fileContent);
                                zip.file(ExporterUtil.getExportFileName() + "/" + name, imageData.fileData)

                            } catch (error) {
                                // TODO
                                throw new Error(error);
                            }
                        }
                    }
                });
            if (groupid && groupnum && name && type === "1") {
                setTimeout(() => {
                    try {
                        zip.generateAsync({ type: "blob" })
                            .then(function (content) {
                                saveAs(content, `${ExporterUtil.getExportFileName()}.zip`);

                            });
                    } catch (error) {
                        // TODO
                        throw new Error(error);
                    }
                }, 3000)
            }
            else {
                try {
                    zip.generateAsync({ type: "blob" })
                        .then(function (content) {
                            saveAs(content, `${ExporterUtil.getExportFileName()}.zip`);

                        });
                } catch (error) {
                    // TODO
                    throw new Error(error);
                }
            }
        }
        else {
            var str = locale === "en" ? `The ${noNum} image is unlabeled` : (locale === "zh-Hans" ? `第${noNum}张图片未标记` : `第${noNum}圖片未標記`);
            alert(str);
        }
    }

    private static getBase64Image(img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        img.setAttribute("crossOrigin", 'Anonymous')
        ctx.drawImage(img, 0, 0, img.width, img.height);
        var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
        var dataURL = canvas.toDataURL("image/" + ext);
        return dataURL;
    }

    private static dataURLtoFile(dataurl,filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        // return new Blob([u8arr], {
        //     type: 'image/jpg'
        // });
        return new File([u8arr], filename, { type: 'image/jpg' });
    }

    private static exportAsVOC(): void {
        // 导出
        var locale = localStorage.getItem("locale");
        var a = true;
        let noNum = 0
        const list = JSON.parse(localStorage.getItem("isSelectLabel"));
        for (var i = 0; i < LabelsSelector.getImagesData().length; i++) {
            if (list[i] === 0) {
                // eslint-disable-next-line no-restricted-globals
                let r = confirm('有图片未标记，是否提交')
                if (r) {
                    a = true
                } else {
                    a = false;
                }
                noNum = i + 1
                break;
            }
        }
        if (a) {
            let zip = new JSZip();
            var fileContent: string = "";
            LabelsSelector.getLabelNames()
                .forEach((labelName: LabelName) => {
                    if (labelName) {
                        try {
                            fileContent += labelName.name.toString() + "\n"
                            zip.file(ExporterUtil.getExportFileName() + "/" + ExporterUtil.getExportFileName() + ".names", fileContent);
                        } catch (error) {
                            // TODO
                            throw new Error(error);
                        }
                    }
                });

            // LabelsSelector.getImagesData().forEach((imageData: ImageData) => {
            //     const fileContent: string = RectLabelsExporter.wrapImageIntoVOC(imageData);
            //     if (fileContent) {
            //         const fileName: string = imageData.fileData.name.replace(/\.[^/.]+$/, ".xml");
            //         try {
            //             zip.file(fileName, fileContent);
            //         } catch (error) {
            //             // TODO
            //             throw new Error(error);
            //         }
            //     }
            // });

            let str = '';
            let val = '';
            let temp = [];

            LabelsSelector.getImagesData()
                .forEach((imageData: ImageData) => {
                    const fileContent: string = RectLabelsExporter.wrapImageIntoVOC(imageData);
                    if (fileContent) {
                        if (imageData.url) {
                            let fileName = null
                            let first = imageData.url.lastIndexOf('/') + 1
                            let end = imageData.url.lastIndexOf('.')
                            fileName = imageData.url.slice(first, end) + '.xml'
                            let images = new Image();
                            images.src = imageData.url;
                            images.onload = async function () {
                                let base64 = await RectLabelsExporter.getBase64Image(images)
                                // console.log(base64)
                                let files = await RectLabelsExporter.dataURLtoFile(base64,imageData.url.slice(first, end) + ".jpg")
                                // let data = new File([files], imageData.url.slice(first, end) + ".jpg", { type: "image/jpg" })
                                // console.log(files, fileName)

                                str += imageData.url.slice(first, end).toString() + "\n"
                                val = imageData.url.slice(first, end).toString() + "\n"
                                try {
                                    zip.file(ExporterUtil.getExportFileName() + "/JPEGImages/" + files.name, files)
                                } catch (error) {
                                    // TODO
                                    throw new Error(error);
                                }
                                if (temp.indexOf(val) == -1) {
                                    temp.push(val)
                                    try {
                                        zip.file(ExporterUtil.getExportFileName() + "/Annotations/" + fileName, fileContent);
                                        zip.file(ExporterUtil.getExportFileName() + "/ImageSets/Main/" + "train.txt", str);
                                        zip.file(ExporterUtil.getExportFileName() + "/ImageSets/Main/" + "val.txt", val);

                                    } catch (error) {
                                        // TODO
                                        throw new Error(error);
                                    }
                                }
                            }
                        } else {
                            // let fileName = null
                            // fileName = imageData.fileData.name.replace(/\.[^/.]+$/, ".txt");
                            let name1 = imageData.fileData.name.slice(0, imageData.fileData.name.lastIndexOf('.')).replace(/\./g, '');
                            let name = name1 + '.jpg'
                            str += name1.toString() + "\n"
                            val = name1.toString() + "\n"

                            if (temp.indexOf(val) == -1) {
                                temp.push(val)
                                try {
                                    zip.file(ExporterUtil.getExportFileName() + "/Annotations/" + name1 + '.xml', fileContent);
                                    zip.file(ExporterUtil.getExportFileName() + "/JPEGImages/" + name, imageData.fileData)
                                    zip.file(ExporterUtil.getExportFileName() + "/ImageSets/Main/" + "train.txt", str);
                                    zip.file(ExporterUtil.getExportFileName() + "/ImageSets/Main/" + "val.txt", val);
    
                                } catch (error) {
                                    // TODO
                                    throw new Error(error);
                                }
                            }

                        }
                    }
                });

            // try {
            //     zip.file(ExporterUtil.getExportFileName() + "/ImageSets/Main/" + "val.txt", temp[temp.length - 1]);

            // } catch (error) {
            //     // TODO
            //     throw new Error(error);
            // }


            if (groupid && groupnum && name && type === "5" || type === "7") {
                setTimeout(() => {
                    try {
                        zip.generateAsync({ type: "blob" })
                            .then(function (content) {
                                saveAs(content, `${ExporterUtil.getExportFileName()}.zip`);

                            });
                    } catch (error) {
                        // TODO
                        throw new Error(error);
                    }
                }, 3000)
            }
            else {
                try {
                    zip.generateAsync({ type: "blob" })
                        .then(function (content) {
                            saveAs(content, `${ExporterUtil.getExportFileName()}.zip`);

                        });
                } catch (error) {
                    // TODO
                    throw new Error(error);
                }
            }
        }
        else {
            var str = locale === "en" ? `The ${noNum} image is unlabeled` : (locale === "zh-Hans" ? `第${noNum}张图片未标记` : `第${noNum}圖片未標記`);
            alert(str);
        }
    }

    private static exportAsVOCUpload(): void {
        var locale = localStorage.getItem("locale");
        var a = true;
        let noNum = 0
        const list = JSON.parse(localStorage.getItem("isSelectLabel"));
        for (var i = 0; i < LabelsSelector.getImagesData().length; i++) {
            if (list[i] === 0) {

                // eslint-disable-next-line no-restricted-globals
                let r = confirm('有图片未标记，是否提交')
                if (r) {
                    a = true
                } else {
                    a = false;
                }
                noNum = i + 1
                break;
            }
        }

        if (a) {
            let zip = new JSZip();

            var fileContent: string = "";

            let str = '';
            let val = '';
            let temp = [];

            LabelsSelector.getLabelNames()
                .forEach((labelName: LabelName) => {
                    if (labelName) {
                        try {
                            fileContent += labelName.name.toString() + "\n"
                            zip.file(ExporterUtil.getExportFileName() + "/" + ExporterUtil.getExportFileName() + ".names", fileContent);
                        } catch (error) {
                            // TODO
                            throw new Error(error);
                        }
                    }
                });

            LabelsSelector.getImagesData()
                .forEach((imageData: ImageData) => {
                    const fileContent: string = RectLabelsExporter.wrapImageIntoVOC(imageData);
                    // console.log(fileContent);
                    if (fileContent) {
                        let fileName = null
                        if (window.location.href.indexOf('groupid') > 0 && type === "5") {
                            let first = imageData.url.lastIndexOf('/') + 1
                            let end = imageData.url.lastIndexOf('.')
                            fileName = imageData.url.slice(first, end) + '.xml'
                            str += imageData.url.slice(first, end).toString() + "\n"
                            val = imageData.url.slice(first, end).toString() + "\n"

                            if (temp.indexOf(val) == -1) {
                                temp.push(val)
                                try {
                                    zip.file(ExporterUtil.getExportFileName() + "/Annotations/" + fileName, fileContent);
                                    zip.file(ExporterUtil.getExportFileName() + "/ImageSets/Main/" + "train.txt", str);
                                    if (imageData.fileData) {
                                        zip.file(ExporterUtil.getExportFileName() + "/JPEGImages/" + imageData.fileData.name, imageData.fileData)
                                    }
                                    zip.file(ExporterUtil.getExportFileName() + "/ImageSets/Main/" + "val.txt", val);
                                } catch (error) {
                                    // TODO
                                    throw new Error(error);
                                }
                            }
                            
                        } else if (type === '7' && name) {
                            let first = imageData.url.lastIndexOf('/') + 1
                            let end = imageData.url.lastIndexOf('.')
                            fileName = imageData.url.slice(first, end) + '.xml'
                            let images = new Image();
                            images.src = imageData.url;
                            images.onload = async function () {
                                let base64 = await RectLabelsExporter.getBase64Image(images)
                                // console.log(base64)
                                let files = await RectLabelsExporter.dataURLtoFile(base64,imageData.url.slice(first, end) + ".jpg")
                                // let data = new File([files], imageData.url.slice(first, end), { type: "image/jpg" })
                                // console.log(data, fileName)

                                str += imageData.url.slice(first, end).toString() + "\n"
                                val = imageData.url.slice(first, end).toString() + "\n"
                                try {
                                    zip.file(ExporterUtil.getExportFileName() + "/JPEGImages/" + files.name, files)
                                } catch (error) {
                                    // TODO
                                    throw new Error(error);
                                }
                                if (temp.indexOf(val) == -1) {
                                    temp.push(val)
                                    try {
                                        zip.file(ExporterUtil.getExportFileName() + "/Annotations/" + fileName, fileContent);
                                        // zip.file(ExporterUtil.getExportFileName() + "/JPEGImages/" + files.name, files)
                                        zip.file(ExporterUtil.getExportFileName() + "/ImageSets/Main/" + "train.txt", str);
                                        zip.file(ExporterUtil.getExportFileName() + "/ImageSets/Main/" + "val.txt", val);
                                    } catch (error) {
                                        // TODO
                                        throw new Error(error);
                                    }
                                }
                                
                            }
                            // str+= imageData.url.slice(first,end).toString() + "\n"
                            // val = imageData.url.slice(first,end).toString() + "\n"
                            // try {
                            //     zip.file(ExporterUtil.getExportFileName() + "/Annotations/" + fileName, fileContent);
                            //     zip.file(ExporterUtil.getExportFileName() + "/ImageSets/Main/" + "train.txt", str);
                            //     if(imageData.fileData){
                            //         zip.file(ExporterUtil.getExportFileName() + "/JPEGImages/" + imageData.fileData.name, imageData.fileData)
                            //     }
                            // } catch (error) {
                            //     // TODO
                            //     throw new Error(error);
                            // }
                        }
                        else {
                            fileName = imageData.fileData.name.replace(/\.[^/.]+$/, ".xml");
                            str += imageData.fileData.name.replace(/\.[^/.]+$/, "").toString() + "\n"
                            val = imageData.fileData.name.replace(/\.[^/.]+$/, "").toString() + "\n"
                            
                            if (temp.indexOf(val) == -1) {
                                temp.push(val)
                                try {
                                    zip.file(ExporterUtil.getExportFileName() + "/Annotations/" + fileName, fileContent);
                                    zip.file(ExporterUtil.getExportFileName() + "/ImageSets/Main/" + "train.txt", str);
                                    zip.file(ExporterUtil.getExportFileName() + "/ImageSets/Main/" + "val.txt", val);
                                    if (imageData.fileData) {
                                        zip.file(ExporterUtil.getExportFileName() + "/JPEGImages/" + imageData.fileData.name, imageData.fileData)
                                    }
                                } catch (error) {
                                    // TODO
                                    throw new Error(error);
                                }
                            }
                        }
                        // const fileName: string = imageData.fileData.name.replace(/\.[^/.]+$/, ".txt");
                        // console.log(imageData.fileData.name.replace(/\.[^/.]+$/, ".txt"));

                    }
                });
            // try {
            //     zip.file(ExporterUtil.getExportFileName() + "/ImageSets/Main/" + "val.txt", val);

            // } catch (error) {
            //     // TODO
            //     throw new Error(error);
            // }
            try {
                if (type === "7" && name) {
                    setTimeout(() => {
                        try {
                            zip.generateAsync({ type: "blob" })
                                .then(function (content) {
                                    let obj = undefined
                                    obj = content
                                    obj.name = `${ExporterUtil.getExportFileName()}.zip`
                                    let fileBlob = new File([obj], `${userid}.zip`, { lastModified: Date.now() });
                                    let params = new FormData();
                                    params.append('id', undefined);
                                    params.append('num', undefined);
                                    params.append('type', 'zip');
                                    params.append('code', `${userid}`);
                                    params.append('file', fileBlob);
                                    axios.post('//traininguser-api.cocorobo.cn/v831_upload', params).then(res => {
                                        if (res.data.FunctionResponse.Error == undefined) {
                                            let obj = {
                                                // "Operation": "get_labeled_dataset_from_local",
                                                "DatasetName": userid,
                                                "DirectoryPath": res.data.FunctionResponse.Path,
                                                "Code": userid
                                            };
                                            axios.post('//traininguser-api.cocorobo.cn/v831_back_up', obj).then(d => {
                                                if (d.data.FunctionResponse.Response.Error == undefined) {
                                                    alert(locale === "en" ? "Tag uploaded successfully" : (locale === "zh-Hans" ? "上传标记成功" : "上傳標記成功"))
                                                    let url = window.location.hostname.indexOf("hk") !== -1 ? "//beta.model-training.cocorobo.hk/detaset" : "//xunlian.cocorobo.cn/detaset"
                                                    window.open(url, '_blank')
                                                } else {
                                                    alert(d.data.FunctionResponse.Response.Error)
                                                }
                                            })
                                        }
                                    })
                                });
                        } catch (error) {
                            // TODO
                            throw new Error(error);
                        }
                    }, 3000)
                }
                else {
                    zip.generateAsync({ type: "blob" })
                        .then(function (content) {
                            let obj = undefined
                            obj = content
                            obj.name = `${ExporterUtil.getExportFileName()}.zip`
                            if (window.location.href.indexOf('groupid') > 0) {
                                let fileBlob = new File([obj], `${ExporterUtil.getExportFileName()}.zip`, { lastModified: Date.now() });
                                // groupid=507ce0d5-7291-11ec-a735-2a16101a060a&groupnum=1
                                let data = new FormData()
                                // console.log('fileBlob', fileBlob)
                                data.append('file', fileBlob)
                                data.append('groupid', String(groupid))
                                data.append('groupnum', String(groupnum))
                                data.append('groupname', String(name))
                                axios.post('//traininguser-api.cocorobo.cn/labelingupload/txt', data).then(res => {
                                    // console.log(res.data.FunctionResponse.Info.indexOf('Successfully'))
                                    if (res.data.FunctionResponse.Info.indexOf('Successfully') !== -1) {
                                        let group = {
                                            userid: userid,
                                            id: groupid,
                                            num: groupnum
                                        }
                                        axios.post("//traininguser-api.cocorobo.cn/group/updategroupdetails", group).then(d => {
                                            // console.log(d.data.FunctionResponse.length === 0)
                                            if (d.data.FunctionResponse.length === 0) {
                                                alert(locale == "en" ? "Tag uploaded successfully" : (locale == "zh-Hans" ? "上传标记成功" : "上傳標記成功"))
                                                let url = window.location.hostname.indexOf("hk") !== -1 ? "//beta.model-training.cocorobo.hk" : "//xunlian.cocorobo.cn"
                                                window.open(url, '_blank')
                                            } else {
                                                alert(d.data.FunctionResponse.error)
                                            }
                                        })
                                    } else {
                                        alert(res.data.FunctionResponse.error)
                                    }
                                })
                            } else if (type === '7') {
                                let fileBlob = new File([obj], `${userid}.zip`, { lastModified: Date.now() });
                                let params = new FormData();
                                params.append('id', undefined);
                                params.append('num', undefined);
                                params.append('type', 'zip');
                                params.append('code', `${userid}`);
                                params.append('file', fileBlob);
                                axios.post('//traininguser-api.cocorobo.cn/v831_upload', params).then(res => {
                                    if (res.data.FunctionResponse.Error == undefined) {
                                        let obj = {
                                            // "Operation": "get_labeled_dataset_from_local",
                                            "DatasetName": userid,
                                            "DirectoryPath": res.data.FunctionResponse.Path,
                                            "Code": userid
                                        };
                                        axios.post('//traininguser-api.cocorobo.cn/v831_back_up', obj).then(d => {
                                            if (d.data.FunctionResponse.Response.Error == undefined) {
                                                alert(locale === "en" ? "Tag uploaded successfully" : (locale === "zh-Hans" ? "上传标记成功" : "上傳標記成功"))
                                                let url = window.location.hostname.indexOf("hk") !== -1 ? "//beta.model-training.cocorobo.hk/detaset" : "//xunlian.cocorobo.cn/detaset"
                                                window.open(url, '_blank')
                                            } else {
                                                alert(d.data.FunctionResponse.Response.Error)
                                            }
                                        })
                                    }
                                })
                            } else {
                                saveAs(content, `${ExporterUtil.getExportFileName()}.zip`);
                            }

                        });
                }
            } catch (error) {
                // TODO
                throw new Error(error);
            }
        }
        else {
            var str = locale === "en" ? `The ${noNum} image is unlabeled` : (locale === "zh-Hans" ? `第${noNum}张图片未标记` : `第${noNum}圖片未標記`);
            alert(str);
        }
    }

    private static wrapRectLabelsIntoVOC(imageData: ImageData): string {
        if (imageData.labelRects.length === 0 || !imageData.loadStatus)
            return null;

        const labelNamesList: LabelName[] = LabelsSelector.getLabelNames();
        const labelRectsString: string[] = imageData.labelRects.map((labelRect: LabelRect) => {
            const labelName: LabelName = findLast(labelNamesList, { id: labelRect.labelId });
            const labelFields = !!labelName ? [
                `\t<object>`,
                `\t\t<name>${labelName.name}</name>`,
                // `\t\t<pose>Unspecified</pose>`,
                `\t\t<truncated>0</truncated>`,
                `\t\t<difficult>0</difficult>`,
                `\t\t<occluded>0</occluded>`,
                `\t\t<bndbox>`,
                `\t\t\t<xmin>${Math.round(labelRect.rect.x)}</xmin>`,
                `\t\t\t<ymin>${Math.round(labelRect.rect.y)}</ymin>`,
                `\t\t\t<xmax>${Math.round(labelRect.rect.x + labelRect.rect.width)}</xmax>`,
                `\t\t\t<ymax>${Math.round(labelRect.rect.y + labelRect.rect.height)}</ymax>`,
                `\t\t</bndbox>`,
                `\t</object>`
            ] : [];
            return labelFields.join("\n")
        });
        return labelRectsString.join("\n");
    }

    private static wrapImageIntoVOC(imageData: ImageData): string {
        const labels: string = RectLabelsExporter.wrapRectLabelsIntoVOC(imageData);
        // const projectName: string = XMLSanitizerUtil.sanitize(GeneralSelector.getProjectName());
        let filename = '';

        if (type === '5' || (type === '7' && name)) {
            let first = imageData.url.lastIndexOf('/') + 1
            let end = imageData.url.lastIndexOf('.')
            filename = imageData.url.slice(first, end) + ".jpg"
        }
        else {
            filename = imageData.fileData.name;
        }
        if (labels) {
            const image: HTMLImageElement = ImageRepository.getById(imageData.id);
            return [
                `<annotation>`,
                // `\t<folder>${projectName}</folder>`,
                `\t<filename>${filename}</filename>`,
                // `\t<path>/${projectName}/${name}</path>`,
                // `\t<source>`,
                // `\t\t<database>Unspecified</database>`,
                // `\t</source>`,
                `\t<size>`,
                `\t\t<width>${image.width}</width>`,
                `\t\t<height>${image.height}</height>`,
                `\t\t<depth>3</depth>`,
                `\t</size>`,
                labels,
                `</annotation>`
            ].join("\n");
        }
        return null;
    }


    private static exportAsCSV(): void {
        const content: string = LabelsSelector.getImagesData()
            .map((imageData: ImageData) => {
                return RectLabelsExporter.wrapRectLabelsIntoCSV(imageData)
            })
            .filter((imageLabelData: string) => {
                return !!imageLabelData
            })
            .join("\n");
        const fileName: string = `${ExporterUtil.getExportFileName()}.csv`;
        ExporterUtil.saveAs(content, fileName);
    }

    private static wrapRectLabelsIntoCSV(imageData: ImageData): string {
        if (imageData.labelRects.length === 0 || !imageData.loadStatus)
            return null;

        const image: HTMLImageElement = ImageRepository.getById(imageData.id);
        const labelNames: LabelName[] = LabelsSelector.getLabelNames();
        const labelRectsString: string[] = imageData.labelRects.map((labelRect: LabelRect) => {
            const labelName: LabelName = findLast(labelNames, { id: labelRect.labelId });
            const labelFields = !!labelName ? [
                labelName.name,
                Math.round(labelRect.rect.x).toString(),
                Math.round(labelRect.rect.y).toString(),
                Math.round(labelRect.rect.width).toString(),
                Math.round(labelRect.rect.height).toString(),
                imageData.fileData.name,
                image.width.toString(),
                image.height.toString()
            ] : [];
            return labelFields.join(",")
        });
        return labelRectsString.join("\n");
    }
}