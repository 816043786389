import React, { useState } from 'react';
import './MainView.scss';
import { TextButton } from "../Common/TextButton/TextButton";
import classNames from 'classnames';
import { ISize } from "../../interfaces/ISize";
import { ImageButton } from "../Common/ImageButton/ImageButton";
import { ISocialMedia, SocialMediaData } from "../../data/info/SocialMediaData";
import { EditorFeatureData, IEditorFeature } from "../../data/info/EditorFeatureData";
import { Tooltip } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import withStyles from "@material-ui/core/styles/withStyles";
import ImagesDropZone from "./ImagesDropZone/ImagesDropZone";

const MainView: React.FC = () => {
    const [projectInProgress, setProjectInProgress] = useState(true);
    const [projectCanceled, setProjectCanceled] = useState(false);

    if (window.location.search.split("lang=")[1]) {
        localStorage.setItem("locale", window.location.search.split("lang=")[1]);
        window.history.replaceState('', '', window.location.origin);
    }
    else {
        if (!localStorage.getItem("locale")) {
            window.location.origin.indexOf("cocorobo.hk") > -1 ?localStorage.setItem("locale", "zh-Hant"):localStorage.setItem("locale", "zh-Hans");
        }
    }

    document.title = localStorage.getItem("locale") == "en" ? "Cocorobo Image Labeling Tool" : (localStorage.getItem("locale") == "zh-Hans" ? "可可乐博图片标记工具" : "可可樂博圖片標記工具")

    const startProject = () => {
        setProjectInProgress(true);
    };

    const endProject = () => {
        setProjectInProgress(false);
        setProjectCanceled(true);
    };

    const getClassName = () => {
        return classNames(
            "MainView", {
            "InProgress": projectInProgress,
            "Canceled": !projectInProgress && projectCanceled
        }
        );
    };

    const DarkTooltip = withStyles(theme => ({
        tooltip: {
            backgroundColor: "#171717",
            color: "#ffffff",
            boxShadow: theme.shadows[1],
            fontSize: 11,
            maxWidth: 120
        },
    }))(Tooltip);

    const changeLanguage = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (event.target.value == "English") {
            localStorage.setItem("locale", "en");
        } else if (event.target.value == "繁体中文") {
            localStorage.setItem("locale", "zh-Hant");
        } else {
            localStorage.setItem("locale", "zh-Hans");
        }
        window.location.reload();
    }

    const getSocialMediaButtons = (size: ISize) => {
        return SocialMediaData.map((data: ISocialMedia, index: number) => {
            return <DarkTooltip
                key={index}
                disableFocusListener
                title={data.tooltipMessage}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                placement="left"
            >
                <div>
                    <ImageButton
                        buttonSize={size}
                        image={data.imageSrc}
                        imageAlt={data.imageAlt}
                        href={data.href}
                    />
                </div>
            </DarkTooltip>
        });
    };

    const getEditorFeatureTiles = () => {
        return EditorFeatureData.map((data: IEditorFeature) => {
            return <div
                className="EditorFeaturesTiles"
                key={data.displayText}
            >
                <div
                    className="EditorFeaturesTilesWrapper"
                >
                    <img
                        draggable={false}
                        alt={data.imageAlt}
                        src={data.imageSrc}
                    />
                    <div className="EditorFeatureLabel">
                        {data.displayText}
                    </div>
                </div>
            </div>
        });
    };

    var locale = localStorage.getItem("locale");
    const style: React.CSSProperties = {
        marginRight: 20,
        color: "black",
        border: "none",
        outline: "none"
    }

    return (
        <div className={getClassName()}>
            {/* <div className="Slider" id="lower">
                <div className="TriangleVertical">
                    <div className="TriangleVerticalContent"/>
                </div>
            </div> */}

            {/* <div className="Slider" id="upper">
                <div className="TriangleVertical">
                    <div className="TriangleVerticalContent"/>
                </div>
            </div> */}

            {/* <div className="LeftColumn">
                <div className="EditorFeaturesWrapper">
                    {getEditorFeatureTiles()}
                </div>
                <div className="TriangleVertical">
                    <div className="TriangleVerticalContent"/>
                </div>
                {projectInProgress && <TextButton
                    label={"Go Back"}
                    onClick={endProject}
                />}
            </div> */}
            <div className="RightColumn">
                <div />
                <ImagesDropZone />
                <div className="SocialMediaWrapper">
                    {getSocialMediaButtons({ width: 30, height: 30 })}
                </div>
                {!projectInProgress && <TextButton
                    label={"Get Started"}
                    onClick={startProject}
                    title={""}
                />}
            </div>

            <div>
                <select id="select" style={style} onChange={changeLanguage} className="select">
                    <option value="English" selected={locale == "en" ? true : false}>English</option>
                    <option value="繁体中文" selected={locale == "zh-Hant" ? true : false}>繁体中文</option>
                    <option value="简体中文" selected={locale == "zh-Hans" ? true : false}>简体中文</option>
                </select>
            </div>
        </div>
    );
};

export default MainView;